import api from '../../api';
import constants from '../../constants';
import URN from '../../urn';

export default {
  [constants.READER_LOAD]: ({ dispatch, commit, state }, {
    leftUrn,
    rightUrn,
    parallelUrn,
    lowerUrn,
    query,
    initial,
  }) => {
    const parallelVersionUrn = parallelUrn ? parallelUrn.value : '';
    let previousParallelVersionUrn = (
      state.leftPassage
      && state.leftPassage.metadata.parallel_version
      && state.leftPassage.metadata.parallel_version.urn
    )
      ? state.leftPassage.metadata.parallel_version.urn
      : undefined;

    // The getPassage API strips the last character (the closing :) from the parallel_version_urn
    // in the query string and sets that as the parallel_version.urn. Add it again to make the
    // comparison work.
    if (previousParallelVersionUrn && previousParallelVersionUrn.slice(-1) !== ':') {
      previousParallelVersionUrn += ':';
    }
    const parallelVersionChanged = parallelVersionUrn !== '' && parallelVersionUrn !== previousParallelVersionUrn;

    if (state.error) {
      commit(constants.SET_ERROR, { error: '' });
    }
    const ps = [];
    if (state.versions === null) {
      ps.push(
        api.getCollection(leftUrn.upTo('work'), (work) => {
          const params = {
            e: work.texts.map(text => new URN(text.urn).version),
          };
          api.getLibraryVector(work.urn, params, (versions) => {
            commit(constants.SET_VERSIONS, { versions });
          });
        }),
      );
    }

    const leftTextUrn = leftUrn.upTo('version');
    if (!state.leftText || state.leftText.urn.toString() !== leftTextUrn) {
      ps.push(
        api.getCollection(leftTextUrn, (text) => {
          commit(constants.SET_LEFT_TEXT, { urn: leftTextUrn, metadata: text });
        }).catch(err => commit(constants.SET_ERROR, { error: err.message })),
      );
    }
    const leftPassageChanged = !state.leftPassage
      || state.leftPassage.urn.toString() !== leftUrn.toString();
    if (leftPassageChanged || parallelVersionChanged) {
      if (!initial) {
        dispatch(constants.READER_SET_SELECTED_TOKEN, { token: null });
      }
      commit(constants.SET_LEFT_PASSAGE_TEXT, { text: null });
      commit(constants.SET_LEFT_PASSAGE, {
        urn: leftUrn,
        ready: false,
        error: '',
        redirected: null,
      });
      const params = { urn: leftUrn, parallelVersionUrn };
      ps.push(
        api.getPassage(params, (passage) => {
          const urn = new URN(passage.urn);
          commit(constants.SET_LEFT_PASSAGE_TEXT, { text: passage.text_html });
          delete passage.text_html;
          commit(constants.SET_LEFT_PASSAGE, { urn });
          if (decodeURI(leftUrn.reference) !== urn.reference) {
            commit(constants.SET_LEFT_PASSAGE, {
              metadata: passage,
              redirected: { previousUrn: leftUrn },
              ready: true,
            });
          } else {
            commit(constants.SET_LEFT_PASSAGE, { metadata: passage, ready: true });
          }
        }).catch((err) => {
          commit(constants.SET_ERROR, { error: err.message });
        }),
      );
    }

    if (rightUrn) {
      const rightTextUrn = rightUrn.upTo('version');
      if (!state.rightText || state.rightText.urn.toString() !== rightTextUrn.toString()) {
        ps.push(
          api.getCollection(rightTextUrn, (text) => {
            commit(constants.SET_RIGHT_TEXT, { urn: rightTextUrn, metadata: text });
          }).catch((err) => {
            commit(constants.SET_ERROR, { error: err.message });
          }),
        );
      }

      if (!state.rightPassage || state.rightPassage.urn.toString() !== rightUrn.toString()) {
        commit(constants.SET_RIGHT_PASSAGE_TEXT, { text: null });
        commit(constants.SET_RIGHT_PASSAGE, {
          urn: rightUrn,
          ready: false,
          error: '',
          redirected: null,
        });
        ps.push(
          api.getPassage({ urn: rightUrn }, (passage) => {
            const urn = new URN(passage.urn);
            commit(constants.SET_RIGHT_PASSAGE_TEXT, { text: passage.text_html });
            delete passage.text_html;
            commit(constants.SET_RIGHT_PASSAGE, { urn });
            if (decodeURI(rightUrn.reference) !== urn.reference) {
              commit(constants.SET_RIGHT_PASSAGE, {
                metadata: passage,
                redirected: { previousUrn: rightUrn },
                ready: true,
              });
            } else {
              commit(constants.SET_RIGHT_PASSAGE, { metadata: passage, ready: true });
            }
          }).catch((err) => {
            commit(constants.SET_RIGHT_PASSAGE, { error: err.message });
          }),
        );
      }
    } else if (state.rightText) {
      commit(constants.SET_RIGHT_PASSAGE, null);
      commit(constants.SET_RIGHT_TEXT, null);
    }

    if (parallelUrn) {
      const parallelReferenceUrn = new URN(parallelUrn.value);

      // The parallelUrn does not have a reference set. We need it to get the data for the sidebar
      // widgets. We copy it from the leftUrn.
      if (parallelReferenceUrn.reference === '') {
        parallelReferenceUrn.reference = leftUrn.reference;
      }

      const parallelTextUrn = parallelReferenceUrn.upTo('version');

      if (!state.parallelText || state.parallelText.urn.toString() !== parallelTextUrn.toString()) {
        ps.push(
          api.getCollection(parallelTextUrn, (text) => {
            commit(constants.SET_PARALLEL_TEXT, { urn: parallelTextUrn, metadata: text });
          }).catch((err) => {
            commit(constants.SET_ERROR, { error: err.message });
          }),
        );
      }

      if (!state.parallelPassage || state.parallelPassage.urn.toString()
          !== parallelReferenceUrn.toString()) {
        commit(constants.SET_PARALLEL_PASSAGE_TEXT, { text: null });
        commit(constants.SET_PARALLEL_PASSAGE, {
          urn: parallelReferenceUrn,
          ready: false,
          error: '',
          redirected: null,
        });

        ps.push(
          api.getPassage({ urn: parallelReferenceUrn }, (passage) => {
            const urn = new URN(passage.urn);
            commit(constants.SET_PARALLEL_PASSAGE_TEXT, { text: passage.text_html });
            delete passage.text_html;
            commit(constants.SET_PARALLEL_PASSAGE, { urn });
            if (decodeURI(parallelReferenceUrn.reference) !== urn.reference) {
              commit(constants.SET_PARALLEL_PASSAGE, {
                metadata: passage,
                redirected: { previousUrn: parallelReferenceUrn },
                ready: true,
              });
            } else {
              commit(constants.SET_PARALLEL_PASSAGE, { metadata: passage, ready: true });
            }
          }).catch((err) => {
            commit(constants.SET_PARALLEL_PASSAGE, { error: err.message });
          }),
        );
      }
    } else if (state.parallelText) {
      commit(constants.SET_PARALLEL_PASSAGE, null);
      commit(constants.SET_PARALLEL_TEXT, null);
    }

    if (lowerUrn) {
      const lowerTextUrn = lowerUrn.upTo('version');
      if (!state.lowerText || state.lowerText.urn.toString() !== lowerTextUrn.toString()) {
        ps.push(
          api.getCollection(lowerTextUrn, (text) => {
            commit(constants.SET_LOWER_TEXT, { urn: lowerTextUrn, metadata: text });
          }).catch((err) => {
            commit(constants.SET_ERROR, { error: err.message });
          }),
        );
      }

      if (!state.lowerPassage || state.lowerPassage.urn.toString() !== lowerUrn.toString()) {
        commit(constants.SET_LOWER_PASSAGE_TEXT, { text: null });
        commit(constants.SET_LOWER_PASSAGE, {
          urn: lowerUrn,
          ready: false,
          error: '',
          redirected: null,
        });
        ps.push(
          api.getPassage({ urn: lowerUrn }, (passage) => {
            const urn = new URN(passage.urn);
            commit(constants.SET_LOWER_PASSAGE_TEXT, { text: passage.text_html });
            delete passage.text_html;
            commit(constants.SET_LOWER_PASSAGE, { urn });
            if (decodeURI(lowerUrn.reference) !== urn.reference) {
              commit(constants.SET_LOWER_PASSAGE, {
                metadata: passage,
                redirected: { previousUrn: lowerUrn },
                ready: true,
              });
            } else {
              commit(constants.SET_LOWER_PASSAGE, { metadata: passage, ready: true });
            }
          }).catch((err) => {
            commit(constants.SET_LOWER_PASSAGE, { error: err.message });
          }),
        );
      }
    } else if (state.lowerText) {
      commit(constants.SET_LOWER_PASSAGE, null);
    }

    return Promise.all(ps).then(() => {
      if (query.highlight && query.highlight !== state.highlight) {
        dispatch(constants.READER_HIGHLIGHT, {
          highlight: query.highlight,
          route: false,
        });
      }
    }).catch((err) => {
      commit(constants.SET_ERROR, { error: `failed to load reader: ${err}` });
    });
  },

  [constants.READER_SET_SELECTED_REF]: ({ commit, state }, { ref }) => {
    if (ref != null) {
      const position = state.availableRefs.indexOf(ref);
      commit(constants.SET_SELECTED_REF_RANGE, { start: position, end: null });
    } else {
      commit(constants.SET_SELECTED_REF_RANGE, { start: null, end: null });
    }
  },

  [constants.READER_SELECT_REF_RANGE]: ({ commit, state }, { ref }) => {
    // The chain below approximates a selection api; a true selection API hew closer to
    // https://developer.mozilla.org/en-US/docs/Web/API/Selection.

    const refPosition = state.availableRefs.indexOf(ref);
    const { start, end } = state.selectedRefRange;
    // The quick brown fox jumped
    if (end === null && refPosition < start) {
      // (The) [quick] brown fox jumped
      // [The quick] brown fox jumped
      commit(constants.SET_SELECTED_REF_RANGE, { start: refPosition, end: start });
    } else if (end === null && refPosition > start) {
      // The [quick] (brown) fox jumped
      // The [quick brown] fox jumped
      commit(constants.SET_SELECTED_REF_RANGE, { end: refPosition });
    } else if (refPosition < start) {
      // (The) [quick brown] fox jumped
      // [The quick brown] fox jumped
      commit(constants.SET_SELECTED_REF_RANGE, { start: refPosition });
    } else if (refPosition > end) {
      // The [quick brown] (fox) jumped
      // The [quick brown fox] jumped
      commit(constants.SET_SELECTED_REF_RANGE, { end: refPosition });
    } else if (refPosition >= start && refPosition <= end) {
      // NOTE: This is where we would really need to deduce user intent per
      // the Selection API, or by monitoring v-on:mousemove.movementY
      // to determine direction.

      // For our purposes, we'll just use the position as the end position.
      commit(constants.SET_SELECTED_REF_RANGE, { end: refPosition });
    } else {
      // FIXME: Prevent this from happening
      commit(constants.SET_SELECTED_REF_RANGE, { start: null, end: null });
    }
  },

  [constants.READER_SET_SELECTED_TOKEN]: ({ dispatch, commit, state }, { token }) => {
    commit(constants.CLEAR_ANNOTATIONS);
    commit(constants.SET_SELECTED_TOKEN_RANGE, { start: token, end: null });

    if (token === null) {
      dispatch(constants.READER_HIGHLIGHT, { highlight: null });
    } else {
      dispatch(constants.READER_HIGHLIGHT, { highlight: `@${state.selectedTokenRange.start}` });
    }
  },

  [constants.READER_SELECT_TOKEN_RANGE]: ({ dispatch, commit, state }, { token }) => {
    if (state.selectedTokenRange.start === null) {
      let passage;

      if (state.textModePane === 'right') {
        if (state.rightPassage) {
          passage = state.rightPassage;
        } else if (state.parallelPassage) {
          passage = state.parallelPassage;
        } else {
          passage = state.leftPassage;
        }
      } else {
        passage = state.leftPassage;
      }

      const firstToken = passage.metadata.word_tokens[0];
      const start = `${firstToken.w}[${firstToken.i}]`;
      commit(constants.SET_SELECTED_TOKEN_RANGE, { start, end: token });
    } else {
      commit(constants.SET_SELECTED_TOKEN_RANGE, { end: token });
    }
    dispatch(constants.READER_HIGHLIGHT, { highlight: `@${state.selectedTokenRange.start}-${state.selectedTokenRange.end}` });
  },

  [constants.READER_TEXT_MODE_PANE]: ({ commit }, { pane }) => {
    if (pane !== null) {
      if (pane !== 'left' && pane !== 'right') {
        pane = 'left';
      }
      commit(constants.SET_TEXT_MODE_PANE, { pane });
    }
  },

  [constants.READER_HIGHLIGHT]: ({ commit, state }, { highlight }) => {
    if (highlight !== null) {
      if (state.mode !== 'clickable') {
        commit(constants.SET_TEXT_MODE, { mode: 'clickable' });
      }
      let singleton = false;
      const selectedTokens = [];
      if (highlight.indexOf('@') === -1) {
        highlight = `@${highlight}`;
      }
      if (highlight.indexOf('-') >= 0) {
        let allTokens;

        if (state.textModePane === 'right') {
          if (state.rightPassage) {
            allTokens = state.rightPassage.metadata.word_tokens;
          } else if (state.parallelPassage) {
            allTokens = state.parallelPassage.metadata.word_tokens;
          } else {
            allTokens = state.leftPassage.metadata.word_tokens;
          }
        } else {
          allTokens = state.leftPassage.metadata.word_tokens;
        }

        let [start, end] = highlight.substr(1).split('-');
        if (start.indexOf('[') === -1) {
          start = `${start}[1]`;
        }
        if (end.indexOf('[') === -1) {
          end = `${end}[1]`;
        }
        const startIdx = allTokens.findIndex(t => `${t.w}[${t.i}]` === start);
        const endIdx = allTokens.findIndex(t => `${t.w}[${t.i}]` === end);
        Array.prototype.push.apply(
          selectedTokens,
          allTokens.slice(Math.min(startIdx, endIdx), Math.max(startIdx, endIdx) + 1).map(t => `${t.w}[${t.i}]`),
        );
      } else {
        if (highlight.indexOf('[') === -1) {
          highlight = `${highlight}[1]`;
        }
        selectedTokens.push(highlight.substr(1));
        singleton = true;
      }
      selectedTokens.forEach((token) => {
        commit(constants.SET_ANNOTATION, {
          token,
          key: 'selected',
          value: true,
          singleton,
        });
      });

      commit(constants.SET_HIGHLIGHT, { highlight });
      // if (route) {
      //   query = { ...query, highlight };
      // }
    } else {
      commit(constants.CLEAR_ANNOTATION, { key: 'selected' });
      commit(constants.SET_HIGHLIGHT, null);
      // if (route) {
      //   query = (({ highlight: deleted, ...o }) => o)(query);
      // }
    }
    // @@@ Should set the route, I guess.  Nothing in Vuex should control routing
    // if (route) {
    // const { default: router } = require('../router'); // eslint-disable-line global-require
    // router.push({ name: 'reader', params: rootState.route.params, query });
    // }
  },
};
