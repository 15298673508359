import SEGWorkList from './library/SEGWorkList.vue';
import CTSCollectionList from './library/metadataCollections/CTSCollectionList.vue';
import MetadataCollectionWorkList from './library/metadataCollections/MetadataCollectionWorkList.vue';

export class RenderOptions {
  constructor(opts) {
    this.opts = opts || {};
  }

  get(key, defaultValue = null) {
    return this.opts[key] !== undefined ? this.opts[key] : defaultValue;
  }

  static fromTextGroup(group) {
    return new RenderOptions(group.metadata.renderOpts);
  }
}

export const WORK_LIST_RENDERERS = {
  'cts-collection-list': CTSCollectionList,
  'metadata-collection-work-list': MetadataCollectionWorkList,
  'seg-work-list': SEGWorkList,
};
