const merge = require('lodash.merge');
const pickBy = require('lodash.pickby');
const isEqual = require('lodash.isequal');

const wasCleaned = function wasCleaned(providedPayload, validatedPayload) {
  return !isEqual(providedPayload, validatedPayload);
};


const cleanPayload = function cleanPayload(providedPayload, allowedPayload) {
  /*
  Compares keys in a `facets` payload with keys in a reference / "allowed"
  payload

  Returns the cleaned payload and boolean indicating if
  cleaning was required.
  */

  // *Keys constants are dervied from allowedPayload at call time
  const validTopLevelKeys = Object.keys(allowedPayload);
  const objectKeys = validTopLevelKeys.filter(key => typeof allowedPayload[key] === 'object');
  const nonObjectKeys = validTopLevelKeys.filter(key => objectKeys.indexOf(key) < 0);

  // validate* functions are dervied from the above constants and the allowedPayload
  // at call time
  const validateTopLevel = function validateTopLevel(value, key) {
    return validTopLevelKeys.indexOf(key) > -1;
  };
  const validateNonObject = function validateNonObject(value, key) {
    return nonObjectKeys.indexOf(key) > -1;
  };
  const validateObjectKeys = function validateObjectKeys(toValidatePayload) {
    /*
    validates nested keys of top-level "object" keys, e.g.
    queries
    lookups
    filters

    This does _not_ validate nested keys past the first level
    */
    return Object.entries(toValidatePayload)
      .filter(entry => objectKeys.indexOf(entry[0]) > -1)
      .reduce((reducedObj, entry) => {
        const key = entry[0];
        const value = entry[1];
        const valid = Object.entries(value).filter((subValue) => {
          const subKey = subValue[0];
          return Object.prototype.hasOwnProperty.call(allowedPayload[key], subKey);
        }).reduce((acc, subEntry) => {
        // eslint-disable-next-line prefer-destructuring
          acc[subEntry[0]] = subEntry[1];
          return acc;
        }, {});
        reducedObj[key] = valid;
        return reducedObj;
      }, {});
  };

  // Now the payload can be cleaned...
  // Remove any top-level keys from the provided payload
  // that aren't on the allowed payload
  let validatedPayload = pickBy(providedPayload, validateTopLevel);

  // Next, validate nested keys
  validatedPayload = {
    ...pickBy(validatedPayload, validateNonObject),
    ...validateObjectKeys(validatedPayload),
  };
  // Ensure that the cleanedPayload has all of the top level / nested keys from
  // the allowed payload
  const cleanedPayload = merge(allowedPayload, validatedPayload);
  return {
    cleanedPayload,
    wasCleaned: wasCleaned(providedPayload, validatedPayload),
  };
};

export {
  // eslint-disable-next-line import/prefer-default-export
  cleanPayload,
};
