/* eslint-disable vue/return-in-computed-property */
<template>
  <div
    class="work-search-result list-group-item"
  >
    <div
      :style="`background-color: ${backgroundColor};`"
      class="product-title-container"
    >
      <a
        v-if="labelLink"
        class="top-label"
        :href="textGroupUrl"
      >
        {{ textGroupLabel }}
      </a>
      <div
        v-else
        class="top-label"
      >
        {{ textGroupLabel }}
      </div>
    </div>
    <h3 class="mt-2 title work-title">
      {{ workName }}
    </h3>
    <div class="mt-2 mb-2">
      <a
        href="#"
        @click.prevent="$emit('passage-results-handler', { product_names: productName, work_labels: workName })"
      >
        <em><HumanizedNumber :value="total" /> {{ resultsLabel }}</em>
      </a>
    </div>
    <!-- TODO: Review my first pass for the flexbox version of this -->
    <!-- TODO: Try this out with the CtsWorkCard approach (would require us to index more) -->
    <!-- TODO: Also pilot a hover / toggle state that loads the cards -->
    <!-- TODO: We could also have "Read" and "Browse" toggles, with read going to the first text, and browse
    to a collection page similar to the library work URN right now-->
    <!-- NOTE: If we go with browse texts, an ATLAS query would absoloutely be appropriate -->
    <VersionDropDown
      class="mt-2 mb-2 version-library-dropdown"
      :work-urn="workUrn"
      :texts="texts"
      :human-lang="firstVersionLang"
      :kind="firstVersionKind"
      @load-texts="loadTexts()"
    />
  </div>
</template>
<script>
import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';
import { LOAD_WORK_VERSIONS } from '@/js/queries';
import URN from '@/js/urn';
import HumanizedNumber from './HumanizedNumber.vue';
import VersionDropDown from './VersionDropDown.vue';

export default {
  components: {
    HumanizedNumber,
    VersionDropDown,
  },
  mixins: [Skeleton2NavigationMixin],
  props: ['result', 'total', 'selectedWork', 'labelLink'],
  data() {
    return {
      showTexts: false,
      loading: false,
      texts: [],
    };
  },
  computed: {
    textGroupLabel() {
      return this.safeValueShim(this.result.se_text_group_label);
    },
    backgroundColor() {
      return this.safeValueShim(this.result.se_product_color);
    },
    workName() {
      return this.safeValueShim(this.result.se_work_label);
    },
    productName() {
      return this.safeValueShim(this.result.se_product_name);
    },
    productReference() {
      return this.safeValueShim(this.result.se_product_reference);
    },
    workUrn() {
      return this.safeValueShim(this.result.work);
    },
    workTexts() {
      return this.safeValueShim(this.result.se_work_texts);
    },
    resultsLabel() {
      return this.total === 1 ? 'passage' : 'passages';
    },
    // FIXME: This is not guaranteed to be the
    // first version in "canonical" order; should
    // be fixed in `se/metadata_collections.py`
    firstVersionLang() {
      return this.result.text.human_lang;
    },
    firstVersionKind() {
      const t = this.result.text
      if( this.textGroupUrl.includes(':vipo')) {
        if( t.kind === 'edition') {
          t.kind = "article"
        }
        else if( e.kind === 'translation') {
          t.kind = "decree"
        }
        else if( e.kind === 'commentary') {
          t.kind = "questionnaire"
        }
      }

      return t.kind;
    },
    textGroupUrl() {
      const tgUrn = new URN(this.workUrn).upTo('textGroup');
      return this.getLibraryURL({ urn: tgUrn });
    },
  },
  mounted() {
    if (this.selectedWork && this.selectedWork === this.workName) {
      this.toggle();
    }
  },
  methods: {
    readerPassageURL(urn) {
      return this.getReaderURL({ urn });
    },
    // TODO: Provide a better hint on either the indexing
    // or retrieval side to avoid this shim
    safeValueShim(value) {
      return Array.isArray(value) ? value[0] : value;
    },
    loadTexts() {
      if (this.texts.length !== 0) {
        return;
      }
      this.loading = true;
      const workUrnStartsWith = `${this.safeURN(this.workUrn)}.`
      this.$gql(LOAD_WORK_VERSIONS, { urn: workUrnStartsWith })
        .then(({ versions }) => {
          this.texts = versions.edges.map(edge => {
            const e = edge.node;
            if( this.textGroupUrl.includes(':vipo')) {
              if( e.kind === 'edition') {
                e.kind = "article"
              }
              else if( e.kind === 'translation') {
                e.kind = "decree"
              }
              else if( e.kind === 'commentary') {
                e.kind = "questionnaire"
              }
            }
            return e
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggle() {
      this.showTexts = !this.showTexts;
      this.loadTexts();
    },
  },
};
</script>

<style lang="scss" scoped>
.version-card {
  min-width: 45%;
  h4.card-title {
    font-size: initial;
  }
  .card-text {
    font-size: initial;
  }
  .card-footer {
    font-size: initial;
  }
}
</style>
