/* eslint-disable vue/return-in-computed-property */
<template>
  <div
    class="list-group-item"
  >
    <div>
      <div
        class="product-title-container"
        :style="`background-color: ${backgroundColor};`"
      >
        <h5
          class="product-title product-title-sm-right"
        >
          {{ productName }}
        </h5>
      </div>
      <div
        class="cover-image-sm-right"
      >
        <div
          v-if="$apollo.queries.manifest.loading || showPlaceholder"
          class="cover-image-placeholder"
        />
        <img
          v-else-if="manifest"
          :src="imgSrc"
          :srcset="imageSrcSet"
          @error="imageError($event)"
        >
      </div>
      <div class="clear-float m-md-2 mx-2 d-block">
        <div class="results">
          <a
            href="#"
            @click.prevent="$emit('passage-results-handler', {product_names: productName})"
            v-if="total"
          >
            <em><HumanizedNumber :value="total" /> {{ resultsLabel }}</em>
          </a>
        </div>
        <div
          class="buttons mt-2 d-flex"
        >
          <a
            v-if="productAboutUrl"
            class="btn btn-light border mr-4"
            :href="productAboutUrl"
          >
            About
          </a>
          <a
            class="btn btn-light border"
            :href="productBrowseUrl"
          >
            Browse
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';

import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';
import HumanizedNumber from './HumanizedNumber.vue';

export default {
  components: {
    HumanizedNumber,
  },
  mixins: [Skeleton2NavigationMixin],
  props: ['result', 'total'],
  data() {
    return {
      showPlaceholder: true,
    };
  },
  computed: {
    dimension() {
      return 125;
    },
    productName() {
      return this.safeValueShim(this.result.se_product_name);
    },
    productReference() {
      return this.safeValueShim(this.result.se_product_reference);
    },
    baseUrl() {
      return this.$router.options.base;
    },
    productBrowseUrl() {
      return `${this.baseUrl}collections/${this.productReference}/redirect/`
    },
    productAboutUrl() {
      return this.productName !== this.productReference
        ? `${this.baseUrl}${this.productReference}` : '';
    },
    resultsLabel() {
      return this.total === 1 ? 'passage' : 'passages';
    },
    imgSrc() {
      return this.manifest && this.manifest.coverImage.src;
    },
    imageSrcSet() {
      return this.manifest && this.manifest.coverImage.srcset;
    },
    backgroundColor() {
      return this.manifest && this.manifest.color;
    },
  },
  apollo: {
    manifest: {
      query: gql`
        query Product($reference: String!) {
          products(reference: $reference) {
            edges {
              node {
                id
                color
                coverImage(dimensions: "200x200") {
                  src
                  srcset
                }
              }
            }
          }
        }
      `,
      variables() {
        return { reference: this.productReference };
      },
      update(data) {
        const manifest = data.products.edges.map(e => e.node)[0];
        this.showPlaceholder = false;
        return manifest;
      },
    },
  },
  methods: {
    readerPassageURL(urn) {
      return this.getReaderURL({ urn });
    },
    // TODO: Provide a better hint on either the indexing
    // or retrieval side to avoid this shim
    safeValueShim(value) {
      return Array.isArray(value) ? value[0] : value;
    },
    imageError() {
      this.showPlaceholder = true;
    },
  },
};
</script>
<style lang="scss" scoped>
  // TODO: Move to scoped components
</style>
// TODO: I have a screenshot with this; also do it with just the images.
