export default {
  text(state) {
    return state.leftText;
  },
  rightText(state) {
    return state.rightText;
  },
  parallelText(state) {
    return state.parallelText;
  },
  passage(state) {
    return state.leftPassage;
  },
  annotations(state) {
    // eslint-disable-next-line no-unused-vars
    const { annotations, annotationChange } = state;
    return annotations;
  },
  selectedWords2(state) {
    const words = [];
    Object.keys(state.annotationsHash).forEach((token) => {
      if (state.annotationsHash[token].selected) {
        const [, w, i] = /^([^[]+)(?:\[(\d+)\])?$/.exec(token);
        words.push({ w, i });
      }
    });
    return words;
  },
  selectedWords(state) {
    const words = [];
    const { annotations } = state;
    annotations.forEach((o, token) => {
      if (o.selected) {
        const [, w, i] = /^([^[]+)(?:\[(\d+)\])?$/.exec(token);
        words.push({ w, i });
      }
    });
    return words;
  },
  selectedRefs(state) {
    const { start, end } = state.selectedRefRange;
    if (start === null) {
      return [];
    }
    if (end === null) {
      return state.availableRefs.slice(start, start + 1);
    }
    if (start > end) {
      // FIXME: Prevent this from ever happening
      return [];
    }
    return state.availableRefs.slice(start, end + 1);
  },
};
