import { render, staticRenderFns } from "./CTSTocList.vue?vue&type=template&id=72c5b49c&"
import script from "./CTSTocList.vue?vue&type=script&lang=js&"
export * from "./CTSTocList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "CTSTocList.vue"
export default component.exports