import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const LOAD_TEXTGROUP_WORKS = gql`
    query TextGroupWorks($urn: String, $first: Int) {
      works(urn_Startswith: $urn, first: $first) {
        edges{
          node {
            urn
            label
          }
        }
      }
      versions(urn_Startswith: $urn) {
        edges {
          node {
            access
            urn
            label
            description
            lang
          }
        }
      }
    }
`;


export const LOAD_WORK_VERSIONS = gql`
    query WorkVersions($urn: String) {
      versions(urn_Startswith: $urn) {
        edges {
          node {
            urn
            label
            kind
            description
            humanLang
            access
            publication {
              version
              title
            }
          }
        }
      }
    }
`;
