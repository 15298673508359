<template>
  <div class="no-access">
    <p class="lead">
      <b>Brill Scholarly Editions</b> would like to grant you access, but you
      do not have the right credentials at the moment. If you would like to
      get access, please visit <a :href="`https://brill.com/${prdCode}/`">
        brill.com/{{ prdCode }}
      </a>. More {{ constants.PRODUCT_LABEL_PLURAL.toLowerCase() }} will be available soon.
    </p>
    <p>
      If you have any questions, please contact us at
      <a href="mailto:sales@brill.com">
        sales@brill.com
      </a>, putting "Scholarly Editions" in the subject line.
    </p>
  </div>
</template>

<script>
import constants from '@/js/constants';

export default {
  name: 'NoAccess',
  data() {
    return { constants };
  },
  computed: {
    prdCode() {
      return this.$route.params.urn.match(/[A-Za-z]+(?=-)/)[0];
    },
  },
};
</script>
