import BaseWidget from './BaseWidget.vue';
import Icon from './Icon.vue';
import PageLoader from './PageLoader.vue';
import AccessIcon from './AccessIcon.vue';
import NoAccess from './NoAccess.vue';
import Embeddable from './Embeddable.vue';


export default [
  Icon,
  BaseWidget,
  PageLoader,
  AccessIcon,
  NoAccess,
  Embeddable,
];
