import { render, staticRenderFns } from "./WorkSearchResultDetail.vue?vue&type=template&id=96013974&scoped=true&"
import script from "./WorkSearchResultDetail.vue?vue&type=script&lang=js&"
export * from "./WorkSearchResultDetail.vue?vue&type=script&lang=js&"
import style0 from "./WorkSearchResultDetail.vue?vue&type=style&index=0&id=96013974&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96013974",
  null
  
)

component.options.__file = "WorkSearchResultDetail.vue"
export default component.exports