<template>
    <icon v-if="access" name="unlock" class="access-granted-lock" />
    <icon v-else name="lock" class="access-denied-lock" />
</template>

<script>
export default {
    name: 'access-icon',
    props: ['access']
}
</script>
