<template>
  <base-widget
    v-if="scaifeConfig.show"
    :key="`${passage.urn}`"
    class="metadata"
    :show-more="showMore"
  >
    <span slot="header">
      {{ scaifeConfig.displayName }}
    </span>
    <div
      slot="body"
      class="metadata"
    >
      <LoaderBall v-if="$apollo.queries.metadataByRef.loading" />
      <EmptyMessage v-if="(!selectedMetadata || selectedMetadata.length === 0) && (!selectedRightMetadata || selectedRightMetadata.length === 0)">
          No entries found.
      </EmptyMessage>

      <!-- LEFT -->
      <MetadataRecord
        :selectedMetadata = "selectedMetadata"
        :side = "selectedRightMetadata ? 'L' : ''"
      />

      <!-- RIGHT -->
      <MetadataRecord
        :selectedMetadata = "selectedRightMetadata"
        :side = "'R'"
      />
    </div>
  </base-widget>
</template>

<script>
import gql from 'graphql-tag';
import { LoaderBall, EmptyMessage } from '@scaife-viewer/common';
import MetadataRecord from "@/js/library/MetadataRecord.vue";

const VISIBILITY_READER = 'reader';

export default {
  name: 'WidgetMetadataRecords',
  components: {
    MetadataRecord,
    EmptyMessage,
    LoaderBall,
  },
  data() {
    return {
      // NOTE: This information would move to the scaifeConfig singleton in SV2
      scaifeConfig: {
        displayName: 'Metadata',
        location: 'sidebar',
        singleton: true,
        show: false,
      },
    };
  },
  computed: {
    showMore() {
        return this.passage.urn.textGroup === 'fgrh'
    },
    passage() {
      return this.$store.getters['reader/passage'];
    },
    rightPassage() {
      return this.$store.state.reader.rightPassage;
    },
    parallelPassage() {
      return this.$store.state.reader.parallelPassage;
    },
    selectedRefs() {
      return this.$store.getters['reader/selectedRefs'];
    },
    // NOTE: Prefer SV 2 urn computed property
    urn() {
      return this.passage
        ? {
          absolute: `${this.passage.urn}`,
        }
        : null;
    },
    rightUrn() {
      return this.rightPassage
        ? {
          absolute: `${this.rightPassage.urn}`,
        }
        : null;
    },
    parallelUrn() {
      return this.parallelPassage
        ? {
          absolute: `${this.parallelPassage.urn}`,
        }
        : null;
    },
    metadataRecords() {
      return this.getMetadataRecords('left');
    },
    metadataRightRecords() {
      return this.getMetadataRecords('right');
    },
    selectedMetadata() {
      return this.metadataForSelectedRefs(this.metadataRecords)
    },
    selectedRightMetadata() {

      if( this.rightUrn) {
        // If we have a right pane without parallel alignment, we show all metadata.
        return this.metadataForSelectedRefs(this.metadataRightRecords)
      }
      else if( this.parallelUrn ) {

        const records = this.metadataForSelectedRefs(this.metadataRightRecords);

        // If we have a right pane with parallel alignment, we show only metadata for refs that are available in the left pane.
        return records.filter((rItem) => {
          return this.selectedMetadata.find(function(lItem) {
            return rItem.ref === lItem.ref
          })
        })
      } else {
        return null;
      }
    },
  },
  watch: {
    // NOTE: This would be replaced by "widget contract"
    // functionality in SV 2
    passage: {
      immediate: true,
      handler() {
        // TODO: Update to query based on metadata collection membership rather than
        // these 'opt-in' flags.
        if (
          this.passage.urn.textGroup === 'fgrh'
          || this.passage.urn.textGroup.slice(0, 4) === 'cso-'
          || this.passage.urn.textGroup === 'dss'
        ) {
          this.scaifeConfig.show = true;
        } else {
          this.scaifeConfig.show = false;
        }
      },
    },
  },
  methods: {
    getMetadataRecords(side) {
      let metadataByRef = undefined;
      let workPartMetadata = undefined;
      let meta = undefined;
      let workPart = this.workPartMetadata; // Left and right pane always belong to the same work

      if( side === 'right') {
        // TODO: Simplify these using `upTo` filters against URNs
        meta = this.metadataRightByRef;
      } else {
        // TODO: Simplify these using `upTo` filters against URNs
        meta = this.metadataByRef;
      }

      metadataByRef = meta && meta.length > 0 ? meta : undefined;
      workPartMetadata = workPart && workPart.length > 0 ? workPart : undefined;

      return metadataByRef || workPartMetadata || [];
    },
    metadataForSelectedRefs(records) {
      return this.selectedRefs.length > 0
        ? records.filter(row => this.isSelected(row.ref))
        : records;
    },
    isSelected(ref) {
      return this.selectedRefs.includes(ref);
    },
  },
  apollo: {
    // TODO: CSO doesn't yet have "passage" level metadata; how would we want to handle that?
    metadataByRef: {
      query: gql`
        query Metadata($urn: String!, $visibility: String!) {
          metadataByRef: passageTextParts(reference: $urn) {
            edges {
              node {
                id
                ref
                records: metadataRecords(visibility: $visibility) {
                  edges {
                    node {
                      id
                      label
                      value
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return { urn: this.urn.absolute, visibility: VISIBILITY_READER };
      },
      update(data) {
        const mappedData = data.metadataByRef.edges.map(e => ({
          ref: e.node.ref,
          recordsByLabel: e.node.records.edges
            .map(e2 => e2.node)
            .reduce((obj, record) => {
              const key = record.label;
              obj[key] = obj[key] || [];
              obj[key].push(record);
              return obj;
            }, {}),
        }));
        return mappedData.filter(v => Object.entries(v.recordsByLabel).length > 0);
      },
      skip() {
        return !this.scaifeConfig.show;
      },
    },
    metadataRightByRef: {
      query: gql`
        query Metadata($urn: String!, $visibility: String!) {
          metadataRightByRef: passageTextParts(reference: $urn) {
            edges {
              node {
                id
                ref
                records: metadataRecords(visibility: $visibility) {
                  edges {
                    node {
                      id
                      label
                      value
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return { urn: this.rightUrn ? this.rightUrn.absolute : this.parallelUrn.absolute, visibility: VISIBILITY_READER };
      },
      update(data) {
        const mappedData = data.metadataRightByRef.edges.map(e => ({
          ref: e.node.ref,
          recordsByLabel: e.node.records.edges
            .map(e2 => e2.node)
            .reduce((obj, record) => {
              const key = record.label;
              obj[key] = obj[key] || [];
              obj[key].push(record);
              return obj;
            }, {}),
        }));
        return mappedData.filter(v => Object.entries(v.recordsByLabel).length > 0);
      },
      skip() {
        return !this.scaifeConfig.show || ( ! this.rightUrn && ! this.parallelUrn );
      },
    },
    workPartMetadata: {
      query: gql`
        query Metadata($urn: String!, $visibility: String!) {
          records: metadataRecords(reference: $urn, visibility: $visibility) {
            edges {
              node {
                id
                label
                value
              }
            }
          }
        }
      `,
      variables() {
        return { urn: this.urn.absolute, visibility: VISIBILITY_READER };
      },
      update(data) {
        // Mimic the format from above
        const preparedObj = {
          ref: '', // NOTE: For compatability with metadataByRef
          recordsByLabel: data.records.edges
            .map(e => e.node)
            .reduce((obj, record) => {
              const key = record.label;
              obj[key] = obj[key] || [];
              obj[key].push(record);
              return obj;
            }, {}),
        };
        const results = [preparedObj];
        return results.filter(v => Object.entries(v.recordsByLabel).length > 0);
      },
      skip() {
        return !this.scaifeConfig.show;
      },
    },
  },
};
</script>
