<template>
  <base-widget class="text-mode">
    <span slot="header">Text Mode</span>
    <div slot="body">
      <div class="mode" v-if="hasRightPane">
        <a :class="[{active: textModePane === 'left'}]" @click.prevent="changeTextModePane('left')">left pane</a>
        <a :class="[{active: textModePane === 'right'}]" @click.prevent="changeTextModePane('right')">right pane</a>
      </div>
      <div class="mode">
        <a :class="[{active: textMode === 'browser'}]" @click.prevent="changeTextMode('browser')">normal</a>
        <a :class="[{active: textMode === 'clickable'}]" @click.prevent="changeTextMode('clickable')">highlight</a>
      </div>
      <div class="help">
        <span v-if="textMode === 'browser'">normal selection of text for copying possible</span>
        <span v-if="textMode === 'clickable'">click and shift-click on words to highlight</span>
      </div>
    </div>
  </base-widget>
</template>

<script>
import constants from '../../constants';

export default {
  name: 'widget-text-mode',
  computed: {
    textModePane() {
      return this.$store.state.reader.textModePane;
    },
    textMode() {
      return this.$store.state.reader.textMode;
    },
    hasRightPane() {
      const hasParallel = this.$route.query.parallel_version_urn !== undefined;
      const hasRight = this.$route.query.right !== undefined;

      if( ! (hasParallel || hasRight) && this.textModePane !== 'left') {
        this.changeTextModePane('left')
      }

      return hasParallel || hasRight;
    },
  },
  mounted() {
    this.initTextModePane();
  },
  methods: {
    initTextModePane() {
        let queryParams = { ...this.$route.query };

        if( queryParams.hasOwnProperty('text_mode_pane')) {
          const pane = queryParams.text_mode_pane
          this.$store.dispatch(`reader/${constants.READER_TEXT_MODE_PANE}`, { pane: pane })
        } else {
          this.$store.dispatch(`reader/${constants.READER_TEXT_MODE_PANE}`, { pane: 'left' })
        }
    },
    changeTextModePane(pane) {
      this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_TOKEN}`, { token: null })
        .then(() => {
          this.$store.dispatch(`reader/${constants.READER_TEXT_MODE_PANE}`, { pane: pane })
          this.updateTextModePaneQueryParam()
      });
    },
    changeTextMode(mode) {
      this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_TOKEN}`, { token: null })
        .then(() => {
          this.$store.commit(`reader/${constants.SET_TEXT_MODE}`, { mode });
        });
    },
    updateTextModePaneQueryParam() {
      let queryParams = { ...this.$route.query };

      if (this.textModePane !== 'right') {
        // text_mode_pane defaults to left
        queryParams = (({ text_mode_pane: deleted, ...o }) => o)(queryParams);
      } else {
        queryParams.text_mode_pane = this.textModePane;
      }
      this.$router.replace({
        query: queryParams,
      });
    },
  },
};
</script>
