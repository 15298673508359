const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_TITLE = 'title';
const SORT_RELEVANCE = 'relevance';

const CSO_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_TITLE,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_TITLE]: {
        label: 'Entry',
        value: ['cso_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_TITLE) {
      return ['cso_work_label.keyword'];
    } if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_TITLE;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.cso_entry_urn[0]),
  queries: {
    // TODO: keyword vs keywords
    keyword: '',
    entry_names: '',
    numbers: '',
    volumes: '',
    types: '',
    languages: '',
    keywords: '',
    traditions: '',
    work_labels: '',
  },
  lookups: {
    entry_names: [],
    numbers: [],
    volumes: [],
    types: [],
    languages: [],
    keywords: [],
    traditions: [],
    work_labels: [],
  },
  filters: {
    keyword: false,
    entry_names: null,
    numbers: null,
    volumes: null,
    types: null,
    languages: null,
    keywords: null,
    traditions: null,
    work_labels: null,
  },
  facets: [
    {
      label: 'Entry Name',
      value: 'entry_names',
      toolTipText: 'Filter by entry name',
      placeholder: '',
    },
    {
      label: 'Language',
      value: 'languages',
      toolTipText: 'Filter by language',
      placeholder: '',
    },
    {
      label: 'Type',
      value: 'types',
      toolTipText: 'Filter by type',
      placeholder: '',
    },
    {
      label: 'Keywords',
      value: 'keywords',
      flatten: true,
      multiple: true,
      toolTipText: 'Search for a specific keyword',
      placeholder: '',
    },
    {
      label: 'Text Group',
      value: 'traditions',
      toolTipText: 'Filter by text group',
      placeholder: '',
    },
    {
      label: 'Work',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: '',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default CSO_SCHEMA;
