<template>
  <div
    class="parallel-container"
  >
      <slot />
  </div>
</template>

<script>
export default {
  name: 'parallel-container',
};
</script>

<style lang="scss" scoped>
</style>
