import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
// TODO: prefer iconMap functionality from @scaife-viewer/common
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons/faWindowRestore';

const iconMap = [
  faArrowLeft,
  faArrowRight,
  faExpand,
  faCog,
  faCompress,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faBook,
  faColumns,
  faSearch,
  faLock,
  faUnlock,
  faQuestionCircle,
  faExclamationCircle,
  faWindowRestore,
].reduce((map, obj) => {
  map[obj.iconName] = obj;
  return map;
}, {});

export default iconMap;
