<template>
  <div class="textpart">
    <router-link
      v-if="reference && isLeaf"
      class="a"
      :to="toRef(reference)"
      :class="{'selected': selected, 'leaf': true}"
      :event="'dblclick'"
      @click.native="handleClick($event)"
    >
      <div>{{ n }}</div>
    </router-link>
    <div
      v-else
      :class="['a', 'not-leaf']"
    >
      <div>{{ n }}</div>
    </div>
    <div class="b">
      <slot />
    </div>
    <template v-if="isParallelReader">
      <component
        :is="renderedParallelText"
        v-if="hasParallelPassageContent"
        class="b"
      />
      <EmptyParallelTextPartNode
        v-if="!hasParallelPassageContent && isLeaf"
        class="b empty-parallel-text-part-node"
      >
        No content found for {{ reference }}
      </EmptyParallelTextPartNode>
    </template>
  </div>
</template>

<script>
import RefLower from './RefLower.vue';
import RenderNote from './RenderNote.vue';
import InlineToken from './InlineToken.vue';
import EmptyParallelTextPartNode from './EmptyParallelTextPartNode.vue';

import ReaderNavigationMixin from '../mixins/ReaderNavigationMixin.vue';
import constants from '../constants';
import ParallelContainer from "./ParallelContainer.vue";

export default {
  name: 'TextPart',
  components: {
    ParallelContainer,
    EmptyParallelTextPartNode,
  },
  mixins: [
    ReaderNavigationMixin,
  ],
  props: {
    reference: {
      type: String,
      default: '',
    },
    isLeaf: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    n() {
      return this.reference.split('.').pop().replace('_', '–');
    },
    selectedRefs() {
      return this.$store.getters['reader/selectedRefs'];
    },
    selected() {
      return this.selectedRefs.includes(this.reference);
    },
    isParallelReader() {
      return this.$route.query.parallel_version_urn;
    },
    passage() {
      return this.$store.getters['reader/passage'];
    },
    parallelPassageLookup() {
      return this.passage ? this.passage.metadata.parallel_passage_lu : {};
    },
    hasParallelPassageContent() {
      // TODO: Find the most performant way to do this
      // return Object.prototype.hasOwnProperty.call(this.siblingContentHash, this.reference);
      return this.parallelPassageContent;
    },
    parallelPassageContent() {
      if (!this.parallelPassageLookup) {
        return undefined;
      }
      const resolved = this.parallelPassageLookup[this.reference];
      if (resolved) {
        return resolved;
      }
      return undefined;
    },
    renderedParallelText() {
      return {
        template: `<parallel-container>${this.parallelPassageContent}</parallel-container>`,
        components: {
          RefLower,
          RenderNote,
          ParallelContainer,
          // TODO: Support nested text parts; not yet required
          // for Jacoby
          // TextPart,
          // FIXME: Support CompiledInlineToken
          t: InlineToken,
        },

      };
    },
  },
  methods: {
    // TODO: Wire up clearing selected ref when we navigate away.
    handleClick($event) {
      if ($event.shiftKey) {
        this.$store.dispatch(`reader/${constants.READER_SELECT_REF_RANGE}`, { ref: this.reference });
      } else if ($event.metaKey && this.selected) {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref: null });
      } else if (this.selected && this.selectedRefs.length === 1) {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref: null });
      } else {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref: this.reference });
      }
      // TODO: Determine proper event; this was a bit buggy in initial work.
      $event.preventDefault();
      // $event.stopPropagation();
    },
  },
};
</script>
