<script>
// This component has a render() method instead of a <template> section because
// it is being loaded at runtime rather than pre-compiled.
import constants from '../constants';

export default {
  name: 'inline-token',
  props: {
    t: { type: String, required: true, },
    w: { type: String, required: true, },
    i: { type: String, required: true, },
  },
  inject: ['highlighting'],
  computed: {
    idx() {
      return `${this.w}[${this.i}]`;
    },
    isParallelPane() {
      let parent = this.$parent;
      while (parent && parent.$options._componentTag !== 'parallel-container') {
          parent = parent.$parent;
      }
      return !!parent;
    },
    isRightPane() {
      let parent = this.$parent;
      while (parent && parent.$options._componentTag !== 'passage-render-text') {
          parent = parent.$parent;
      }
      return parent.$props.pane==='right';
    },
    isVisible() {
      let parent = this.$parent;
      while (parent && parent.$options._componentTag !== 'text-part') {
          parent = parent.$parent;
      }
      return parent.visible === true
    }
  },
  render(h) {
    let selected = false;
    let highlighted = false;
    let clickable = false;
    const {
      t, idx, isParallelPane, isRightPane, isVisible, highlighting,
      $parent: p,
      $store: store,
    } = this;

    // annotationsHash is not used, but range highlighting will not work when it is removed.
    const { textMode, textModePane, annotations, annotationsHash } = store.state.reader;

    const isLeftEnabled = ! isRightPane && ! isParallelPane && textModePane === "left";
    const isRightEnabled = (isRightPane || isParallelPane) && textModePane === "right";

    if (isVisible && highlighting) {
      if (textMode === 'clickable') {
        clickable = true;
      }
      if( isLeftEnabled || isRightEnabled ) {
        if (annotations.has(idx)) {
          ({ selected, highlighted } = annotations.get(idx));
        }
      }
    }
    return h(
      'span',
      {
        class: [
          t,
          { c: clickable, selected, highlighted },
        ],
        on: {
          click(e) {
            if (clickable && (isLeftEnabled || isRightEnabled)) {
              if (e.metaKey) {
                if (selected) {
                  store.dispatch(`reader/${constants.READER_SET_SELECTED_TOKEN}`, { token: null });
                }
              } else if (e.shiftKey) {
                store.dispatch(`reader/${constants.READER_SELECT_TOKEN_RANGE}`, { token: idx });
              } else {
                store.dispatch(`reader/${constants.READER_SET_SELECTED_TOKEN}`, { token: idx });
              }
              e.stopPropagation();
            }
          },
        },
      },
      this.$slots.default,
    );
  },
};
</script>
