<template>
  <a class="note" lang="en-Latn" v-note="{content: content, lang: languageScript}">
    <sup v-if="n">{{ n }}</sup>
    <template v-else>*</template>
  </a>
</template>

<script>
import ReaderNavigationMixin from '../mixins/ReaderNavigationMixin.vue';

export default {
  name: 'RenderNote',
  mixins: [
    ReaderNavigationMixin,
  ],
  props: ['content', 'n', 'languageScript'],
};
</script>
