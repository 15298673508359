<template>
  <base-widget class="passage-links">
    <span slot="header">
      Cite This
    </span>
    <div slot="body">
      <template v-if="rightText || parallelText">
        <p>
          <span class="side">
            L
          </span>
          <span v-if="leftTextPublicationStatement">
            <p><span v-html="leftText.metadata.description" /></p>
            <p><span v-html="leftText.metadata.publication_statement" /></p>
          </span>
          <span v-else v-html="leftText.metadata.description" />
        </p>
        <p>
          <span class="side">
            R
          </span>
          <span v-if="rightTextPublicationStatement">
            <p><span v-html="rightText.metadata.description" /></p>
            <p><span v-html="rightText.metadata.publication_statement" /></p>
          </span>
          <span v-else-if="rightText" v-html="rightText.metadata.description" />
          <span v-else-if="parallelTextPublicationStatement">
            <p><span v-html="parallelText.metadata.description" /></p>
            <p><span v-html="parallelText.metadata.publication_statement" /></p>
          </span>
          <span v-else-if="parallelText" v-html="parallelText.metadata.description" />
        </p>
      </template>
      <template v-else>
        <p><span v-html="text.metadata.description" /></p>
        <p><span v-if="publicationStatement" v-html="text.metadata.publication_statement" /></p>
      </template>
      <a
        v-if="productPageURL"
        :href="productPageURL"
        class="btn btn-light border"
      >
        More info
      </a>
    </div>
  </base-widget>
</template>

<script>

export default {
  name: 'WidgetTextMetadata',
  computed: {
    text() {
      return this.$store.getters['reader/text'];
    },
    leftText() {
      return this.$store.state.reader.leftText;
    },
    rightText() {
      return this.$store.state.reader.rightText;
    },
    parallelText() {
      return this.$store.state.reader.parallelText;
    },
    reference() {
      return this.text.metadata.manifest.reference;
    },
    productPageURL() {
      return (this.reference ? `${this.$router.options.base}${this.reference}` : null);
    },
    publicationStatement() {
      return this.text.metadata.publication_statement;
    },
    leftTextPublicationStatement() {
      if (!this.leftText) {
        return null;
      }
      return this.leftText.metadata.publication_statement;
    },
    rightTextPublicationStatement() {
      if (!this.rightText) {
        return null;
      }
      return this.rightText.metadata.publication_statement;
    },
    parallelTextPublicationStatement() {
      if (!this.parallelText) {
        return null;
      }
      return this.text.metadata.publication_statement;
    },
  },
};
</script>
