<template>
  <div class="mt-2 mb-2">
    <span class="mr-2">Group By:</span>
    <span class="dropdown">
      <button
        id="dropdownMenuButton"
        class="btn btn-sm btn-outline-primary active dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ capFirst(activeLensLabel) }}
      </button>
      <div
        class="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
        <span
          v-for="[lens_, data] in availableLenses"
          :key="lens_"
          class="dropdown-item"
          @click.prevent="$emit('update-lens', lens_)"
        >
          {{ capFirst(data.pluralLabel) }}
        </span>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  props: ['lenses', 'selectedLens'],
  computed: {
    availableLenses() {
      return this.lenses.filter(([lens_, data]) => lens_ !== this.selectedLens);
    },
    activeLensLabel() {
      return this.lenses.filter(([lens_, data]) => lens_ === this.selectedLens)[0][1].pluralLabel;
    },
  },
  methods: {
    capFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
