import ApolloClient from 'apollo-boost';
import VueApollo from 'vue-apollo';
// eslint-disable-next-line import/extensions
import fetch from 'unfetch';
import router from '../router';

const apolloConfig = {
  uri: process.env.VUE_APP_ATLAS_GRAPHQL_ENDPOINT
    || `${router.options.base}atlas/graphql/`,
  fetch,
};

const client = new ApolloClient(apolloConfig);

const GraphQLPlugin = {
  install: (Vue) => {
    Vue.mixin({
      data: () => ({ gqlData: null }),
      computed: {
        gqlQuery: () => null,
      },
      watch: {
        gqlQuery: {
          immediate: true,
          handler() {
            if (this.gqlQuery) {
              this.$gql(this.gqlQuery).then((data) => {
                this.gqlData = data;
              });
            }
          },
        },
      },
    });

    // eslint-disable-next-line no-param-reassign,max-len
    Vue.prototype.$gql = (q, variables) => client.query({ query: q, variables }).then(data => data.data);
  },
};

const apolloProvider = new VueApollo({
  defaultClient: client,
});

// TODO: remove `client` export
export { client as gqlclient, apolloProvider };
export default GraphQLPlugin;
