<template>
  <div class="form-group">
    <div class="row">
      <div class="col-sm-6">
        {{ field.min.filterLabel }}
        <input
          :value="minValue"
          :placeholder="field.min.placeholder"
          type="number"
          class="form-control"
          @input="$emit('update-field', field.min.value, $event.target.value)"
        >
      </div>
      <div class="col-sm-6">
        {{ field.max.filterLabel }}
        <input
          :value="maxValue"
          :placeholder="field.max.placeholder"
          type="number"
          class="form-control"
          @input="$emit('update-field', field.max.value, $event.target.value)"
        >
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['minValue', 'maxValue', 'field'],
};
</script>
