import WorkSearchResultDetail from '../WorkSearchResultDetail.vue';
import VersionSearchResultDetail from '../VersionSearchResultDetail.vue';


const LENS_WORK = 'work';
const LENS_FINE = 'fine';

const SORT_CANONICAL = 'canonical';
const SORT_WORK_LABEL = 'work_label';
const SORT_RELEVANCE = 'relevance';

const generateCollectionSchema = function generateCollectionSchema() {
  return {
    defaultLens: LENS_WORK,
    sortFields: {
      default: SORT_CANONICAL,
      fields: {
        [SORT_RELEVANCE]: {
          label: 'Relevance',
          value: ['_score', 'urn'],
          keywordOnly: true,
        },
        [SORT_CANONICAL]: {
          label: 'Library Order',
          value: ['se_product_name.keyword', 'canonical_sort'],
        },
        [SORT_WORK_LABEL]: {
          label: 'Work Name',
          value: ['se_work_label.keyword'],
        },
      },
    },
    sortParamsFunc: (($vm) => {
      if ($vm.sort === SORT_CANONICAL) {
        return ['se_product_name.keyword', 'canonical_sort.keyword'];
      } if ($vm.sort === SORT_WORK_LABEL) {
        return ['se_work_label.keyword'];
      } if ($vm.sort === SORT_RELEVANCE) {
        return ['_score', 'urn'];
      }
      return [];
    }),
    keywordWatcherFunc: (($vm, newValue, oldValue) => {
      if (!oldValue && !$vm.userHasSorted) {
        $vm.sort = SORT_RELEVANCE;
      } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
        $vm.sort = SORT_CANONICAL;
        $vm.userHasSorted = false;
      }
    }),
    coarseResultKeyFunc: (result => result.se_product_reference[0]),
    workResultKeyFunc: (result => result.work[0]),
    versionResultKeyFunc: (result => result['text.urn'][0]),
    queries: {
      keyword: '',
      work_labels: '',
      version_labels: '',
      version_kinds: '',
      version_languages: '',
    },
    lookups: {
      work_labels: [],
      version_labels: [],
      version_kinds: [],
      version_languages: [],
    },
    filters: {
      keyword: false,
      work_labels: null,
      version_labels: null,
      version_kinds: null,
      version_languages: null,
    },
    facets: [
      {
        label: 'Work',
        value: 'work_labels',
        toolTipText: 'Filter by work',
        placeholder: '',
      },
      {
        label: 'Version',
        value: 'version_labels',
        toolTipText: 'Filter by version',
        placeholder: '',
      },
      {
        label: 'Category',
        value: 'version_kinds',
        toolTipText: 'Filter by category (edition, translation, commentary, etc.)',
        placeholder: '',
      },
      {
        label: 'Language',
        value: 'version_languages',
        toolTipText: 'Filter by version language',
        placeholder: '',
      },
    ],
    lenses: {
      work: {
        label: 'work',
        pluralLabel: 'works',
        kind: 'work',
        sortFields: new Set([
          SORT_RELEVANCE,
          SORT_CANONICAL,
          SORT_WORK_LABEL,
        ]),
      },
      version: {
        label: 'version',
        pluralLabel: 'versions',
        kind: 'version',
        sortFields: new Set([
          SORT_RELEVANCE,
          SORT_CANONICAL,
          SORT_WORK_LABEL,
        ]),
      },
      fine: {
        label: 'passage',
        pluralLabel: 'passages',
        kind: LENS_FINE,
        sortFields: new Set([
          SORT_RELEVANCE,
          SORT_CANONICAL,
          SORT_WORK_LABEL,
        ]),
      },
    },
    versionLensComponent: VersionSearchResultDetail,
    workLensComponent: WorkSearchResultDetail,
  };
};
const CTS_COLLECTION_SCHEMA = generateCollectionSchema();

export default CTS_COLLECTION_SCHEMA;
export { generateCollectionSchema };
