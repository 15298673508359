<template>
  <div>
    <p class="lead">
      The requested resource could not be found.
    </p>
    <p>
      If you have any questions, please contact us at
      <a href="mailto:sales@brill.com">
        sales@brill.com
      </a>, putting
      "Scholarly Editions" in the subject line.
    </p>
  </div>
</template>

<script>
export default {};
</script>
