<template>
  <div>
    <SearchGuide v-if="revealHelp" />
    <div class="input-group">
      <div class="input-group-prepend">
        <span
          class="btn dropdown-toggle border"
          :class="{'btn-primary': showForm, 'btn-light': !showForm}"
          @click="$emit('toggle-form')"
        >
          <span class="fa fa-filter" /> Filter
        </span>
      </div>
      <input
        ref="searchInput"
        v-model="userValue"
        type="text"
        class="border-left search-query form-control"
        placeholder="Search..."
        @keyup="handleKeyup"
      >
      <div class="input-group-append">
        <span
          class="input-group-text btn search-btn"
          @click.prevent="handleUserSearch()"
        >
          <icon name="search" />
        </span>
      </div>
      <span
        class="btn btn-link ml-4 help-btn"
        :class="{'active': revealHelp }"
        @click.prevent="$emit('toggle-reveal-help')"
      >
        <icon name="question-circle" />
      </span>
    </div>
  </div>
</template>
<script>
import SearchGuide from '@/js/library/search/SearchGuide.vue';

export default {
  components: {
    SearchGuide,
  },
  props: ['value', 'revealHelp', 'showForm', 'lookahead'],
  data() {
    return {
      userValue: this.value,
    };
  },
  methods: {
    handleKeyup($event) {
      const shouldEmit = (
        $event.key === 'Enter' || this.lookahead
      );
      return shouldEmit ? this.handleUserSearch() : false;
    },
    handleUserSearch() {
      this.$emit('on-user-search', this.userValue);
    },
  },
};
</script>
