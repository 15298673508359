<template>
  <div
    class="search-result list-group-item"
  >
    <div
      :style="`background-color: ${backgroundColor};`"
      class="product-title-container"
    >
      <a
        v-if="labelLink"
        class="top-label"
        :href="workUrl"
      >
        {{ textGroupLabel }}, {{ workName }}
      </a>
      <div
        v-else
        class="top-label"
      >
        {{ textGroupLabel }}, {{ workName }}
      </div>
    </div>

    <h4 class="mt-2 universal-heading version-heading">
      <a :href="readerPassageURL(text.urn)">
        <span>
          {{ versionLabel }}
        </span>
      </a>
    </h4>
    <p
      class="card-text version-description mt-2"
      v-html="text.description"
    />
    <span class="mt-2 mb-2">
      <a
        href="#"
        @click.prevent="$emit('passage-results-handler', { product_names: productName, work_labels: workName, version_labels: versionLabel })"
      >
        <em><HumanizedNumber :value="total" /> {{ resultsLabel }}</em>
      </a>
    </span>
    <div class="mt-2 mb-2">
      <a
        class="btn btn-light border read-text-link"
        :href="readerPassageURL(text.urn)"
      >
        <icon name="book" /> Read {{ text.human_lang || 'Arabic' }} {{ text.kind || 'edition' }}
      </a>
    </div>
  </div>
</template>
<script>
import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';
import URN from '@/js/urn';
import HumanizedNumber from './HumanizedNumber.vue';

export default {
  components: {
    HumanizedNumber,
  },
  mixins: [Skeleton2NavigationMixin],
  props: ['result', 'total', 'labelLink'],
  computed: {
    backgroundColor() {
      return this.safeValueShim(this.result.se_product_color);
    },
    textGroupLabel() {
      return this.safeValueShim(this.result.se_text_group_label);
    },
    workName() {
      return this.safeValueShim(this.result.se_work_label);
    },
    text() {
      const t = this.result.text;

      if( t.urn.includes(':vipo')) {
        if( t.kind === 'edition') {
          t.kind = "article"
        }
        else if( t.kind === 'translation') {
          t.kind = "decree"
        }
        else if( t.kind === 'commentary') {
                t.kind = "questionnaire"
        }
      }
      return t;
    },
    versionLabel() {
      return this.result.text.label;
    },
    resultsLabel() {
      return this.total === 1 ? 'passage' : 'passages';
    },
    workUrl() {
      const workUrn = new URN(this.result.text.urn).upTo('work');
      return this.getLibraryURL({ urn: workUrn });
    },
  },
  methods: {
    readerPassageURL(urn) {
      return this.getReaderURL({ urn });
    },
    safeValueShim(value) {
      return Array.isArray(value) ? value[0] : value;
    },

  },
};
</script>
<style lang="scss" scoped>
.version-description {
  color: #868e96;
}
</style>
