<template>
  <div>
    <div
      v-if="loading"
      class="plot-loader"
    >
      <page-loader />
    </div>
    <div
      v-if="!loading"
      class="plotly__container"
    >
      <figure class="plotly__figure">
        <div class="plotly-expansion-control">
          <icon
            v-b-modal="url"
            name="expand"
            size="sm"
          />
        </div>
        <plotly
          class="plotly"
          :data="data"
          :layout="layout"
          v-bind="config"
        />
      </figure>

      <b-button
        :id="'datamenu-' + id"
        variant="default"
        class="datamenu-control"
        size="sm"
      >
        Data <img
          :src="brillImage"
          width="20"
        >
      </b-button>

      <b-modal
        :id="url"
        centered
        hide-footer
        size="xl"
        @shown="() => this.modalOpen = true"
        @hidden="() => this.modalOpen = false"
      >
        <div slot="default">
          <figure class="plotly__figure">
            <plotly
              v-if="modalOpen"
              class="plotly"
              :data="data"
              :layout="layout"
              v-bind="config"
            />
          </figure>
          <div
            class="row"
            style="padding: 0 40px;"
          >
            <div class="col-6">
              <div>
                <dl>
                  <dd
                    v-if="meta.plot && meta.plot.description_html"
                    class="plotly__caption"
                    v-html="meta.plot.description_html"
                  />
                  <dd
                    v-if="citation"
                    class="plotly__citation"
                    v-html="citation"
                  />
                </dl>
              </div>
            </div>
            <div class="col-6">
              <div>
                <dl>
                  <dt>Dataset</dt>
                  <dd>
                    <a
                      target="_blank"
                      :href="meta.database.url"
                    >
                      {{ meta.database.title }}
                    </a>(<a :href="meta.database.doi">
                      {{ meta.database.doi }}
                    </a>)
                  </dd>
                  <dt>Query</dt>
                  <dd>
                    <a
                      target="_blank"
                      :href="meta.query.url"
                    >
                      {{ meta.query.title }}
                    </a>
                  </dd>
                  <dt v-if="meta.database.zenodo_url">
                    Zenodo
                  </dt>
                  <dd v-if="meta.database.zenodo_url">
                    <a :href="meta.database.zenodo_url">
                      {{ meta.database.zenodo_url }}
                    </a>
                  </dd>
                  <dt>Extras</dt>
                  <dd>
                    <ul class="datamenu-explore">
                      <li>
                        <a
                          :href="meta.database.extras.binder_url"
                          target="_blank"
                        >
                          Jupyter Notebook
                        </a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-popover
        :target="`datamenu-${id}`"
        triggers="click"
      >
        <div>
          <dl>
            <dt>Database</dt>
            <dd>
              <a
                :href="meta.database.url"
                target="_blank"
              >
                {{ meta.database.title }}
              </a>
              (<a
                :href="meta.database.doi"
                target="_blank"
              >
                {{ meta.database.doi }}
              </a>)
            </dd>
            <dt>Query</dt>
            <dd>
              <a
                :href="meta.query.url"
                target="_blank"
              >
                {{ meta.query.title }}
              </a>
            </dd>
            <dt v-if="meta.database.zenodo_url">
              Zenodo
            </dt>
            <dd v-if="meta.database.zenodo_url">
              <a
                :href="meta.database.zenodo_url"
                target="_blank"
              >
                {{ meta.database.zenodo_url }}
              </a>
            </dd>
            <dt>Extras</dt>
            <dd>
              <ul class="datamenu-explore">
                <li>
                  <a
                    :href="meta.database.extras.binder_url"
                    target="_blank"
                  >
                    Jupyter Notebook
                  </a>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </b-popover>
      <div
        v-if="meta.plot && meta.plot.description_html"
        class="plotly__caption"
        v-html="meta.plot.description_html"
      />
      <div
        v-if="citation"
        class="plotly__citation"
        v-html="citation"
      />
    </div>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly';
import PageLoader from './PageLoader.vue';


const defaultConfig = { displayModeBar: false, responsive: true };
const defaultLayout = {
  font: { family: 'Brill', size: 14 },
  margin: {
    l: 40, t: 40, b: 40, r: 40, pad: 0,
  },
};

export default {
  name: 'Plot',
  components: { Plotly, PageLoader },
  props: {
    url: { type: String, default: null },
    brillImage: { type: String, default: null },
  },
  data() {
    return {
      id: null,
      loading: true,
      data: [],
      layout: {},
      config: {},
      meta: {},
      hover: false,
      modalOpen: false,
    };
  },
  computed: {
    citation() {
      let citation = '';

      const { database, plot } = this.meta;
      if (!database) {
        return null;
      }

      const {
        publisher, doi, authors, publication_date,
      } = database;
      if (authors && authors.length > 0) {
        citation += `${authors.map(author => `${author.given} ${author.family}`).join(', ')}.`;
      }
      if (publication_date) {
        citation += ` ${publication_date}.`;
      }
      if (plot && plot.title) {
        citation += ` "${plot.title}" In <i>${database.title || 'Unknown Database'}</i>`;
      }
      if (publisher) {
        citation += `, ${publisher.location}: ${publisher.name}`;
      }
      if (doi) {
        citation += `, ${doi}`;
      }

      return citation;
    },
  },
  watch: {
    url: {
      async handler() {
        const hashBuffer = await crypto.subtle.digest('SHA-1', new TextEncoder().encode(this.url));
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        this.id = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      },
      immediate: true,
    },
  },
  created() {
    fetch(this.url)
      .then(r => r.json())
      .then(({
        data, layout, config, meta,
      }) => {
        this.data = data;
        this.layout = { ...defaultLayout, ...layout };
        this.config = { ...defaultConfig, ...(config || {}) };
        this.meta = meta || {};
      })
      .finally(() => {
        this.loading = false;
      });
  },
};

</script>

<style lang="scss">
.plotly__caption, .plotly__citation {
  font-size: 0.95rem;
}

.modal {
  .plotly__caption, .plotly__citation {
    padding-bottom: 20px;
  }
}

.plot-loader {
  width: 100%;
  height: 300px;
  display: flex;
}

.datamenu-explore {
  list-style-type: none;
  margin-block-start: 0;
  padding-left: 0;

  li {
    margin-left: 0;
  }
}

.datamenu-control {
  float: right;
  border: 1px solid #ccc;
  margin-left: 5px;
  margin-top: 5px;
}

.plotly {
  &__container {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  &__figure {
    margin: 0;
  }
}

.modal-backdrop {
  opacity: .5;
}

.plotly-expansion-control {
  position: absolute;
  right: 10px;
  z-index: 2;
  font-size: 0.9em;
  cursor: pointer;
  color: #6C757D;

  :hover {
    color: darken(#6C757D, 50%);
  }

  :focus {
    outline: 0;
  }
}
</style>
