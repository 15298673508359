<template>
  <div class="top-navbar" v-if="passage.urn.textGroup === 'pez'" >
      <div class="top-navbar-button">
        <a :href="prevLink" v-if="prevLink">
          <span><icon name="arrow-left"></icon></span>
          PREV
        </a>
      </div>
      <div class="top-navbar-button">
        <a :href="nextLink" v-if="nextLink">
          <span><icon name="arrow-right"></icon></span>
          NEXT
        </a>
      </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import URN from "@/js/urn";

const VISIBILITY_READER = 'reader';

export default {
  name: 'TopNavBarPez',
  computed: {
    passage() {
      return this.$store.getters['reader/passage'];
    },
    urn() {
      return this.passage
        ? {
          absolute: `${this.passage.urn}`,
        }
        : null;
    },
    prevLink() {
      let link = "";
      if(  this.workPartMetadataRecords && this.workPartMetadataRecords.recordsByLabel) {
        if ('previous' in this.workPartMetadataRecords.recordsByLabel) {
          link = this.formatLink(this.workPartMetadataRecords.recordsByLabel.previous[0])
        }
      }
      return link;
    },
    nextLink() {
      let link = "";
      if(  this.workPartMetadataRecords && this.workPartMetadataRecords.recordsByLabel) {
        if ('next' in this.workPartMetadataRecords.recordsByLabel) {
          link = this.formatLink(this.workPartMetadataRecords.recordsByLabel.next[0])
        }
      }
      return link;
    },
    workPartMetadataRecords() {
      return this.getWorkPartMetadataRecords();
    },
  },
  methods: {
    formatLink(record) {
      const data = Object.assign({}, record);

      const targetUrn = new URN(this.urn.absolute)
      const letterBand = data.value.split("-")
      const letter = letterBand[0]
      targetUrn.work = String(letter).padStart(4, '0');

      // Target version might have a different band number compared to current version
      let versionParts = targetUrn.version.split("-")
      versionParts[1] = letterBand[1]
      targetUrn.version = versionParts.join("-")
      let link= targetUrn.upTo('version')

      return `/reader/${link}`
    },
    getWorkPartMetadataRecords() {
      const workPart = this.workPartMetadata;
      const workPartMetadata = workPart && workPart.length > 0 ? workPart[0] : undefined;

      return workPartMetadata || [];
    }
  },
  apollo: {
    workPartMetadata: {
      query: gql`
        query Metadata($urn: String!, $visibility: String!) {
          records: metadataRecords(reference: $urn, visibility: $visibility) {
            edges {
              node {
                id
                datatype
                label
                value
                valueObj
              }
            }
          }
        }
      `,
      variables() {
        return { urn: this.urn.absolute, visibility: VISIBILITY_READER };
      },
      update(data) {
        // Mimic the format from above
        const preparedObj = {
          ref: '', // NOTE: For compatability with metadataByRef
          recordsByLabel: data.records.edges
            .map(e => e.node)
            .reduce((obj, record) => {
              const key = record.label;
              obj[key] = obj[key] || [];
              //obj[key].push(this.formatForDisplay(record));
              obj[key].push(record);
              return obj;
            }, {}),
        };
        const results = [preparedObj];
        return results.filter(v => Object.entries(v.recordsByLabel).length > 0);
      },
      skip() {
        return this.passage.urn.textGroup !== 'pez';
      },
    },
  },
};
</script>
