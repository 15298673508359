<template>
  <div class="search-guide">
    <h3>Search Guide</h3>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>+</kbd>
          </div>
          <div class="col">
            AND operation, results displayed only include both terms: shoe + boot
          </div>
        </div>
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>|</kbd>
          </div>
          <div class="col">
            OR operation, results displayed include either (or both) terms: shoe | boot
          </div>
        </div>
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>-</kbd>
          </div>
          <div class="col">
            NOT operation, excludes a term from the search results: shoe -boot
          </div>
        </div>
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>"</kbd>
          </div>
          <div class="col">
            Put a word or phrase inside quotes to search for an exact match: "in the night"
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>*</kbd>
          </div>
          <div class="col">
            at the end of a word to display results for all ending variations: amat*
          </div>
        </div>
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>(</kbd> <kbd>)</kbd>
          </div>
          <div class="col">
            use parentheses to specify the order of the search actions: shoe + (boot | socks)
          </div>
        </div>
        <div class="row">
          <div class="col first-fixed-width">
            <kbd>~<var>num</var></kbd>
          </div>
          <div class="col">
            after a word or phrase signifies fuzziness of search i.e. shoe~1 : shoe, shot, she or
            “In the night”~1: in the night, in the first night, in the cold night
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  // TODO: Factor out to a variables file
  $font-family-secondary: 'Noto Sans', 'Noto Sans Coptic';
  $brill-blue: #0f356c;
  $brand-color: $brill-blue;
  $gray-600: #868e96;
  $gray-100: #f8f9fa;
  $gray-700: #495057;

  .search-guide {
    h3 {
      font-family: $font-family-secondary;
      font-size: 14px;
      margin: 0 0 0.5em;
      color: $gray-600;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
    margin: 1.0em 0;
    background: $gray-100;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 24px;
    color: $gray-700;
    kbd {
      background: $brand-color
    }
    .first-fixed-width {
      width: 4rem;
      max-width: 5rem;
    }
  }
</style>
