<template>
  <component
    :is="workListComponent"
    v-if="textGroup"
    :text-group="textGroup"
    :work-deck-component="workDeckComponent"
  />
</template>

<script>
import gql from 'graphql-tag';
import { RenderOptions, WORK_LIST_RENDERERS } from '../rendering';

export default {
  name: 'WorkListFactory',
  components: { ...WORK_LIST_RENDERERS },
  computed: {
    textGroup() {
      return this.gqlData ? this.gqlData.textGroups.edges[0].node : null;
    },
    renderOptions() {
      return RenderOptions.fromTextGroup(this.textGroup);
    },
    workDeckComponent() {
      if (this.textGroup) {
        return this.renderOptions.get('workDeckRenderer', 'cts-work-deck');
      }
      return 'page-loader';
    },
    workListComponent() {
      if (this.textGroup) {
        const renderer = this.renderOptions.get('workListRenderer', 'cts-collection-list');
        return renderer in WORK_LIST_RENDERERS ? renderer : null;
      }
      return 'page-loader';
    },
    urn() {
      return this.$route.params.urn;
    },
    gqlQuery() {
      return gql`
        {
          textGroups (urn_Startswith:"${this.urn}") {
            edges {
              node {
                urn
                metadata
              }
            }
          }
        }
      `;
    },
  },
};
</script>
