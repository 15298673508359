<template>
  <!-- TODO: Refactor with SearchTextGroups -->
  <div class="search-text-groups">
    <!-- hidden on sm and xs -->
    <div class="d-none d-md-block">
      <h5 v-if="showClearProduct">
        <span>{{ constants.PRODUCT_LABEL_PLURAL }}</span>
        &nbsp;
        <small
          class="link-text clear-btn"
          @click="handleClear()"
        >
          clear
        </small>
      </h5>
      <h5 v-if="!showClearProduct">
        {{ constants.PRODUCT_LABEL_PLURAL }}
      </h5>
      <div class="list-group">
        <a
          v-for="ftp in firstTenProducts"
          v-if="!seeMore"
          :key="ftp.obj.urn"
          class="list-group-item d-flex justify-content-between align-items-center link-text"
          @click="handleSearch(1, ftp.obj.urn)"
        >
          <span>{{ ftp.obj.label }}</span>
          <span class="badge badge-primary badge-pill">
            {{ ftp.count }}
          </span>
        </a>
        <a
          v-for="ftp in allProducts"
          v-if="seeMore"
          :key="ftp.obj.urn"
          class="list-group-item d-flex justify-content-between align-items-center link-text"
          @click="handleSearch(1, ftp.obj.urn)"
        >
          <span>{{ ftp.obj.label }}</span>
          <span class="badge badge-primary badge-pill">
            {{ ftp.count }}
          </span>
        </a>
        <div class="link-container">
          <small>
            <span
              v-if="!seeMore && !showClearProduct && showSeeMore"
              class="link-text"
              @click="toggleProducts()"
            >
              <span><i class="fas fa-chevron-down" /></span>
              &nbsp;See More
            </span>
            <span
              v-if="seeMore && !showClearProduct && showSeeMore"
              class="link-text"
              @click="toggleProducts()"
            >
              <span><i class="fas fa-chevron-up" /></span>
              &nbsp;See Less
            </span>
          </small>
        </div>
      </div>
    </div>
    <!-- visible on sm and xs -->
    <div class="d-md-none">
      <h5>
        <span>Text Groups</span>
        <span
          v-show="!showProducts"
          @click="handleShowProductsChange"
        >
          <i class="far fa-caret-square-down link-text" />
        </span>
        <span
          v-show="showProducts"
          @click="handleShowProductsChange"
        >
          <i class="far fa-caret-square-up link-text" />
        </span>
        <small
          v-if="showClearProduct"
          class="link-text"
          @click="handleClear()"
        >
          &nbsp;clear
        </small>
      </h5>
      <div
        class="list-group"
        :style="{'display':showProducts?'block':'none'}"
      >
        <a
          v-for="ftp in firstTenProducts"
          :key="ftp.obj.urn"
          class="list-group-item d-flex justify-content-between align-items-center link-text"
          @click="handleSearch(1, ftp.obj.urn)"
        >
          <span>{{ ftp.obj.label }}</span>
          <span class="badge badge-primary badge-pill">
            {{ ftp.count }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '../../constants';

export default {
  name: 'SearchProducts',
  props: [
    'handleSearch', 'products', 'showClearProduct', 'showProducts',
    'handleShowProductsChange', 'clearWorks',
  ],
  data() {
    return {
      constants,
      seeMore: false,
      showSeeMore: true,
    };
  },
  computed: {
    allProducts() {
      return this.products;
    },
    firstTenProducts() {
      this.showSeeMore = false;
      if ((this.products).length >= 10) {
        this.showSeeMore = true;
      }
      return this.products.slice(0, 10);
    },
  },
  methods: {
    toggleProducts() {
      this.seeMore = !this.seeMore;
    },
    handleClear() {
      if (!this.$route.query.q) {
        // NOTE: Since the user is clearing the product,
        // but we want to show "root" results, we need to
        // unload product from state completely
        window.location = `${this.$router.options.base}${this.$route.path.substring(1)}`;
        return;
      }
      this.handleSearch(0);
      this.clearWorks();
    },
  },
};
</script>
