import URN from '@scaife-viewer/common';
import createDefaultSVStore, { MODULE_NS } from '@scaife-viewer/store';

const passageGetter = (state, getters, rootState, rootGetters) => {
  /*
  Mimics the passage getter from @scaife-viewer/store
  */
  const passage = rootGetters['reader/passage'];
  if (passage) {
    return new URN(passage.urn.value);
  }
  return null;
};

const firstCitationSchemeLabelGetter = (state, getters, rootState, rootGetters) => {
  /*
  Mimics the firstCitationSchemeLabelGetter getter from @scaife-viewer/store
  */

  const { metadata } = rootGetters['reader/text'];
  return metadata.toc ? metadata.toc[0].label : '';
};
// eslint-disable-next-line arrow-body-style
const selectedLemmasGetter = (state, getters, rootState) => {
  /*
  Mimics the selectedLemmas getter from @scaife-viewer/store
  */
  return rootState.reader.selectedLemmas;
};


const createStoreShim = function createStoreShim() {
  const defaultStore = createDefaultSVStore().store;
  return {
    namespace: MODULE_NS,
    store: {
      namespaced: true,
      state: {
        ...defaultStore.state(),
      },
      getters: {
        ...defaultStore.getters,
        passage: passageGetter,
        firstCitationSchemeLabel: firstCitationSchemeLabelGetter,
        selectedLemmas: selectedLemmasGetter,
      },
      actions: {
        ...defaultStore.actions,
      },
      mutations: {
        ...defaultStore.mutations,
      },
    },
  };
};
export default createStoreShim;
