<template>
  <div id="body_wrapper" class="wrapper">

    <div :class="['sidebar', { collapsed: !sidebarLeftOpened, 'both-opened': sidebarLeftOpened && sidebarRightOpened }]" id="left-sidebar">
      <button class="close-left" v-if="sidebarLeftOpened" @click="toggleSidebar('left')"><icon name="arrow-left"></icon></button>
      <div>
        <slot name="left"></slot>
      </div>
    </div>

    <button class="open-left" v-if="!sidebarLeftOpened" @click="toggleSidebar('left')"><icon name="arrow-right"></icon></button>

    <section id="content_body" v-on:scroll.passive="handleScroll">
      <slot name="body"></slot>
    </section>

    <button class="open-right" v-if="!sidebarRightOpened" @click="toggleSidebar('right')"><icon name="arrow-left"></icon></button>

    <div :class="['sidebar', { collapsed: !sidebarRightOpened, 'both-opened': sidebarLeftOpened && sidebarRightOpened }]" id="right-sidebar">
      <button class="close-right" v-if="sidebarRightOpened" @click="toggleSidebar('right')"><icon name="arrow-right"></icon></button>
      <div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '../constants';

export default {
  name: 'Skeleton',
  computed: {
    sidebarLeftOpened() {
      return this.$store.state.reader.sidebarLeftOpened;
    },
    sidebarRightOpened() {
      return this.$store.state.reader.sidebarRightOpened;
    },
  },
  methods: {
    toggleSidebar(side) {
      switch (side) {
        case 'left':
          this.$store.commit(`reader/${constants.READER_TOGGLE_SIDEBAR_LEFT}`);
          break;
        case 'right':
          this.$store.commit(`reader/${constants.READER_TOGGLE_SIDEBAR_RIGHT}`);
          break;
        default:
      }
    },
    handleScroll(e) {
      if (document.getElementById("content_body").scrollTop > 90) {
        document.getElementsByTagName("nav")[0].classList.add('shrink')
        document.getElementById("body_wrapper").classList.add('grow')
      }
      else if (document.getElementById("content_body").scrollTop < 40) {
        document.getElementsByTagName("nav")[0].classList.remove('shrink')
        document.getElementById("body_wrapper").classList.remove('grow')
      }
    },
  },
};
</script>
