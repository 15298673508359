<template>
  <div class="tg-landing">
    <div class="row mb-4">
      <div class="col-sm-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              @click="toggleForm"
            >
              <span class="fa fa-filter" /> Filter
            </button>
          </div>
          <input
            v-model="queries.keyword"
            type="text"
            class="form-control"
            placeholder="Search..."
            @keyup="() => { firstPage(); search(); }"
          >
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-sm-12 d-flex">
        <div
          v-show="queries.keyword"
          class="btn btn-sm btn-primary mr-2"
          @click="resetQuery('keyword')"
        >
          Search: "{{ queries.keyword }}" <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-show="filters.entry"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('entry')"
        >
          Entry: "{{ filters.entry }}" <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-show="filters.volume"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('volume')"
        >
          Volume: {{ filters.volume }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-show="filters.type"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('type')"
        >
          Entry Type: "{{ filters.type }}" <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="filters.people && filters.people.length"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('people')"
        >
          Person: {{ filters.people.map(p => `"${p}"`).join(" + ") }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="filters.places && filters.places.length"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('places')"
        >
          Place: {{ filters.places.map(p => `"${p}"`).join(" + ") }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="filters.dates && filters.dates.length"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('dates')"
        >
          Period: "{{ filters.dates }}" <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-show="queries.not_before"
          class="btn btn-sm btn-primary mr-2"
          @click="resetQuery('not_before')"
        >
          Year &gt;= {{ queries.not_before }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-show="queries.not_after"
          class="btn btn-sm btn-primary mr-2"
          @click="resetQuery('not_after')"
        >
          Year &lt;= {{ queries.not_after }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="filters.index_terms && filters.index_terms.length"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('index_terms')"
        >
          Index terms: {{ filters.index_terms.map(t => `"${t}"`).join(" + ") }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-show="filters.inscription_types"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('inscription_types')"
        >
          Inscription Type: "{{ filters.inscription_types }}" <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="filters.references"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('references')"
        >
          Referencing: {{ filters.references.map(r => `"${r}"`).join(" + ") }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="filters.concordances"
          class="btn btn-sm btn-primary mr-2"
          @click="resetFilter('concordances')"
        >
          Concordances: {{ filters.concordances.map(c => `"${c}"`).join(" + ") }} <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <div
          v-if="activeCriteriaKeys().length > 0"
          class="btn btn-sm btn-danger ml-auto"
          @click="resetAll"
        >
          Clear all <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-4 justify-content-center">
      <div
        v-if="showForm"
        class="tg-facets form col-lg-3 col-md-9 mb-5"
      >
        <div class="card">
          <div class="card-header">
            Filter
          </div>
          <div class="card-body">
            <div class="form-group">
              <div
                v-tooltip="'Filter entries by volume'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>Volume</label>
              <v-select
                v-model="filters.volume"
                :options="filteredOptions(lookups.volume, filters.volume)"
                placeholder="Type to filter..."
                @search="(query, loadFn) => searchFilter('volume', query, loadFn)"
                @search:blur="refreshOptions('volume')"
              />
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Search for places by city, region or country'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>
                Place
              </label>
              <v-select
                v-model="filters.places"
                multiple
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.places, filters.places)"
                @search="(query, loadFn) => searchFilter('places', query, loadFn)"
                @search:blur="refreshOptions('places')"
              />
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Search for Archaic, Classical, etc. or per century: early IV BC, II AD, etc.'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>
                Period
              </label>
              <v-select
                v-model="filters.dates"
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.dates, filters.dates)"
                @search="(query, loadFn) => searchFilter('dates', query, loadFn)"
                @search:blur="refreshOptions('dates')"
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  From
                  <input
                    v-model="queries.not_before"
                    placeholder="-900"
                    type="text"
                    class="form-control"
                    @input="search"
                  >
                </div>
                <div class="col-sm-6">
                  Until
                  <input
                    v-model="queries.not_after"
                    placeholder="800"
                    type="text"
                    class="form-control"
                    @input="search"
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Search for a specific index term'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>
                Index Terms
              </label>
              <v-select
                v-model="filters.index_terms"
                multiple
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.index_terms, filters.index_terms)"
                @search="(query, loadFn) => searchFilter('index_terms', query, loadFn)"
                @search:blur="refreshOptions('index_terms')"
              />
              Operator:
              <span class="float-right">
                <label>
                  AND
                  <input
                    v-model="operators.index_terms"
                    type="radio"
                    name="index_terms_operator"
                    value="AND"
                  >
                </label>
                <label>
                  OR
                  <input
                    v-model="operators.index_terms"
                    type="radio"
                    name="index_terms_operator"
                    value="OR"
                  >
                </label>
              </span>
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Search by specific bibliographic reference'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>
                Reference
              </label>
              <v-select
                v-model="filters.references"
                multiple
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.references, filters.references)"
                @search="(query, loadFn) => searchFilter('references', query, loadFn)"
                @search:blur="refreshOptions('references')"
              />
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Search for an inscription type'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>
                Inscription Type
              </label>
              <v-select
                v-model="filters.inscription_types"
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.inscription_types, filters.inscription_types)"
                @search="(query, loadFn) => searchFilter('inscription_types', query, loadFn)"
                @search:blur="refreshOptions('inscription_types')"
              />
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Search for a specific inscription. For IG numbers, please use the format “IG II-2 1670”.'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>
                Concordances
              </label>
              <v-select
                v-model="filters.concordances"
                multiple
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.concordances, filters.concordances)"
                @search="(query, loadFn) => searchFilter('concordances', query, loadFn)"
                @search:blur="refreshOptions('concordances')"
              />
            </div>
            <div class="form-group">
              <div
                v-tooltip="'Filter entries by type'"
                class="float-right"
              >
                <i class="fas fa-info icon-small" />
              </div>
              <label>Entry Type</label>
              <v-select
                v-model="filters.type"
                placeholder="Type to filter..."
                :options="filteredOptions(lookups.type, filters.type)"
                @search="(query, loadFn) => searchFilter('type', query, loadFn)"
                @search:blur="refreshOptions('type')"
              />
            </div>
          </div>
        </div>
      </div>
      <page-loader v-if="loading" />
      <div
        v-else-if="total > 0"
        :class="{'col-md-9': showForm, 'col-md-10': !showForm}"
        class="tg-facets results"
      >
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                Found {{ total }} entries
              </div>
              <!-- TODO: Refactor similar to lenses in MetadataCollectionWorkList.vue -->
              <div class="col-sm-6 text-right">
                sorted by:
                <template v-if="queries.keyword">
                  <a
                    href="#"
                    :class="sort === 'relevance' ? 'font-weight-bold' : ''"
                    @click.prevent="updateSort('relevance')"
                  >
                    <span>Relevance</span>
                  </a>
                  /
                  <a
                    href="#"
                    :class="sort === 'publicationDate' ? 'font-weight-bold' : ''"
                    @click.prevent="updateSort('publicationDate')"
                  >
                    <span>
                      Publication
                      Date
                    </span>
                  </a>
                </template>
                <span
                  v-else
                  class="font-weight-bold"
                >
                  Publication
                  Date
                </span>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <a
              v-for="result in results"
              :key="result.urn"
              :href="getReaderURL(result)"
              class="list-group-item list-group-item-action"
            >
              <h5 class="title">
                {{ result.title }}
              </h5>
              <div class="urn">
                {{ result.urn }}
              </div>
            </a>
          </div>
          <div class="card-footer">
            <nav
              aria-label="Entry pagination"
              class="justify-content-between d-flex"
            >
              <ul class="pagination pagination-sm mb-0">
                <li class="d-flex flex-column justify-content-center">
                  Page {{ currentPage }} of {{ totalPages }}
                </li>
              </ul>
              <div class="btn-group">
                <button
                  :disabled="!hasPreviousPage()"
                  class="btn btn-primary ml-0"
                  title="First page"
                  @click="firstPage"
                >
                  &lt;&lt;
                </button>
                <button
                  :disabled="!hasPreviousPage()"
                  class="btn btn-primary ml-0"
                  title="Previous page"
                  @click="() => hasPreviousPage() && previousPage()"
                >
                  Previous
                </button>
                <button
                  :disabled="!hasNextPage()"
                  class="btn btn-primary ml-0"
                  title="Next page"
                  @click="nextPage"
                >
                  Next
                </button>
                <button
                  :disabled="!hasNextPage()"
                  class="btn btn-primary ml-0"
                  title="Last page"
                  @click="lastPage"
                >
                  &gt;&gt;
                </button>
              </div>
            </nav>
          </div>
        </div>
        <page-loader v-if="loading" />
      </div>
      <div v-else-if="!loading" :class="{'col-md-9': showForm, 'col-md-10': !showForm}">
        No results found. Please try again.
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import api from '../api';
import Skeleton2NavigationMixin from '../mixins/Skeleton2NavigationMixin.vue';
import { syncSessionStorage } from '@/js/storage';
import { b64DecodeUnicode, b64EncodeUnicode } from '@/js/strUtils';
import { TextGroupSearch } from '../tracking';

const debounce = require('lodash.debounce');

const DEBOUNCE_TIMEOUT = 300;
const SORT_PUBLICATION_DATE = 'publicationDate';
const SORT_RELEVANCE = 'relevance';

// Empty OpenIndicator
VueSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', ''),
  },
});

function truthy(value) {
  return value && (Number.isInteger(value) || value.length > 0);
}


function parseComponentState(stringifiedState) {
  return JSON.parse(b64DecodeUnicode(stringifiedState));
}

function stringifyComponentState(component, keys) {
  return b64EncodeUnicode(JSON.stringify(Object.fromEntries(keys.map(k => [k, component[k]]))));
}

function loadStateFromHistory() {
  const search = new URLSearchParams(window.location.search);
  const facetState = search.get('facets');
  return facetState ? parseComponentState(facetState) : undefined;
}

function loadStateFromSession() {
  const facetState = syncSessionStorage.getItem('facets');
  return facetState ? parseComponentState(facetState) : undefined;
}

function getInitialData() {
  const defaults = {
    total: 0,
    offset: 0,
    size: 50,
    userHasSorted: false,
    sort: SORT_PUBLICATION_DATE,
    loading: true,
    facetLoading: null,
    showForm: false,
    results: [],
    operators: {
      index_terms: 'AND',
    },
    queries: {
      keyword: '',
      places: '',
      dates: '',
      index_terms: '',
      entry: '',
      volume: '',
      type: '',
      inscription_types: '',
      concordances: '',
      references: '',
    },
    lookups: {
      places: [],
      dates: [],
      index_terms: [],
      entry: [],
      volume: [],
      type: [],
      inscription_types: [],
      concordances: [],
      references: [],
    },
    filters: {
      keyword: false,
      not_before: false,
      not_after: false,
      places: null,
      dates: null,
      index_terms: null,
      entry: null,
      volume: null,
      type: null,
      inscription_types: null,
      concordances: null,
      references: null,
    },
  };
  // TODO: Add env var to toggle persistedState
  const persistedState = loadStateFromHistory() || loadStateFromSession() || {};
  return persistedState ? { ...defaults, ...persistedState } : defaults;
}

export default {
  components: { 'v-select': VueSelect },
  mixins: [Skeleton2NavigationMixin],
  props: ['textGroup'],
  data: getInitialData,
  computed: {
    urn() {
      return this.$route.params.urn;
    },
    currentPage() {
      return Math.round(this.offset / this.size) + 1;
    },
    totalPages() {
      const pagesRequired = this.total / this.size;
      return Math.ceil(pagesRequired);
    },
  },
  watch: {
    sort() {
      this.search();
    },
    offset() {
      this.loading = true;
      this.search();
    },
    size() {
      this.search();
    },
    filters: {
      deep: true,
      handler() {
        this.$counter.track('Search', TextGroupSearch(this.textGroup));
        this.firstPage();
        this.search();
      },
    },
    operators: {
      deep: true,
      handler() {
        this.loading = true;
        this.search();
      },
    },
    queries: {
      deep: true,
      handler(value) {
        Object.keys(value).forEach((k) => {
          if (this.filters[k] === false) {
            return;
          }
          if (value[k] && value[k] !== this.filters[k]) {
            this.lookup(k, value[k]);
          }
        });
      },
    },
    'queries.keyword': {
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue && !this.userHasSorted) {
          this.sort = SORT_RELEVANCE;
        } else if (!newValue && this.sort === SORT_RELEVANCE) {
          this.sort = SORT_PUBLICATION_DATE;
          this.userHasSorted = false;
        }
      },
    },
  },
  created() {
    this.search();
  },
  methods: {
    firstPage() {
      this.offset = 0;
      this.loading = true;
    },
    lastPage() {
      this.offset = (this.totalPages - 1) * this.size;
    },
    nextPage() {
      this.offset += this.size;
    },
    hasNextPage() {
      return this.offset + this.size < this.total;
    },
    previousPage() {
      this.offset -= this.size;
    },
    hasPreviousPage() {
      return this.offset > 0;
    },
    getSortParams() {
      return this.sort === SORT_PUBLICATION_DATE ? [] : ['_score', 'urn.keyword'];
    },
    searchParams() {
      const params = { group_urn: this.urn };

      Object.keys(this.operators).forEach((k) => {
        params[`${k}_operator`] = this.operators[k];
      });

      Object.keys(this.filters).forEach((k) => {
        if (this.filters[k]) {
          params[k] = this.filters[k];
        }
      });

      if (this.queries.keyword) {
        params.keyword = this.queries.keyword;
      }

      if (this.queries.not_before) {
        params.not_before = this.queries.not_before;
      }

      if (this.queries.not_after) {
        params.not_after = this.queries.not_after;
      }

      return params;
    },
    search: debounce(function f() {
      const params = {
        offset: this.offset,
        size: this.size,
        sort: this.getSortParams(),
        ...this.searchParams(),
      };
      const facetState = stringifyComponentState(this, [
        'filters',
        'queries',
        'operators',
        'offset',
        'size',
        'sort',
        'showForm',
      ]);
      window.history.pushState(null, null, `?facets=${facetState}`);
      syncSessionStorage.setItem('facets', facetState);
      api.searchFacets(params, ({ hits, total }) => {
        this.total = total;
        this.results = hits;
        this.initialLookup();
        this.loading = false;

        if (this.facetLoading) {
          this.facetLoading(false);
          this.facetLoading = null;
        }

        if (this.showForm) {
          // FIXME: Can we do this in a less brute-force fashion?
          this.$el.scrollIntoView();
        }
      });
    }, DEBOUNCE_TIMEOUT),
    lookup: debounce(function f(facet, include) {
      const params = {
        group_urn: this.urn,
        include,
        facet,
        ...this.searchParams(),
      };
      api.facetLookup(params, ({ aggregations }) => {
        this.lookups[facet] = aggregations[facet].buckets.map(b => b.key);

        if (this.facetLoading) {
          this.facetLoading(false);
          this.facetLoading = null;
        }
      });
    }, DEBOUNCE_TIMEOUT),
    initialLookup() {
      const facets = Object.keys(this.filters);
      const params = {
        group_urn: this.urn, include: '', facet: facets.join(','), ...this.searchParams(),
      };
      api.facetLookup(params, ({ aggregations }) => {
        facets.forEach((facet) => {
          const aggregation = aggregations[facet];
          this.lookups[facet] = aggregation ? aggregation.buckets.map(b => b.key) : [];
        });
      });
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    resetFilter(key) {
      this.queries[key] = '';
      this.filters[key] = null;
      this.firstPage();
    },
    resetQuery(key) {
      this.queries[key] = '';
      this.firstPage();
      this.search();
    },
    activeFilterKeys() {
      return Object.keys(this.filters).filter(k => truthy(this.filters[k]));
    },
    activeQueryKeys() {
      return Object.keys(this.queries).filter(k => truthy(this.queries[k]));
    },
    activeCriteriaKeys() {
      return this.activeQueryKeys().concat(this.activeFilterKeys());
    },
    resetAll() {
      // TODO: Refactor using https://github.com/ianwalter/vue-component-reset
      this.activeFilterKeys().forEach((k) => {
        this.filters[k] = null;
      });
      this.activeQueryKeys().forEach((k) => {
        this.queries[k] = '';
      });
      this.firstPage();
      this.search();
      this.userHasSorted = false;
    },
    searchFilter(key, value, loadFn) {
      if (value) {
        this.facetLoading = loadFn;
        this.facetLoading(true);
      }
      this.queries[key] = value;
    },
    refreshOptions(key) {
      // TODO: Improve via upstream feature/seg-metadata-collections
      // branch (where we can stop potentially expensive)
      // refreshes
      const queryVal = this.queries[key];
      const filterVal = this.filters[key];
      if (queryVal && !filterVal) {
        this.resetQuery(key);
      }
      return true;
    },
    filteredOptions(options, valueIsh) {
      if (!valueIsh) {
        return options;
      }
      // TODO: Backport to upstream feature/seg-metadata-collections
      const valueIshArray = !Array.isArray(valueIsh) ? [valueIsh] : valueIsh;
      if (valueIshArray.length > 0) {
        return options.filter(value => !valueIshArray.includes(value));
      }
      return options;
    },
    updateSort(value) {
      this.sort = value;
      if (this.queries.keyword && value !== SORT_RELEVANCE) {
        this.userHasSorted = true;
      }
    },
  },
};
</script>

<style>
.v-select .vs__dropdown-menu {
  width: auto;
  max-width: 200%;
  min-width: 100%;
}
</style>
