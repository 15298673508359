<script>
export default {
  computed: {
    sqsFlagSet() {
      return document.querySelectorAll('[data-use-sqs-search=true]').length > 0;
    },
    useSqs() {
      if (this.sqsFlagSet) {
        return true;
      }
      return this.$route.query.use_sqs || '1';
    },
  },
  methods: {
    searchParams(includeGroupUrn = false) {
      const params = {
        collection_urn: this.collectionUrn,
        lens_scope: this.lens,
        use_sqs: this.useSqs,
      };

      // TODO: Remove dep on group_urn
      if (includeGroupUrn) {
        params.group_urn = this.urn;
      }

      Object.keys(this.operators).forEach((k) => {
        params[`${k}_operator`] = this.operators[k];
      });

      Object.keys(this.filters).forEach((k) => {
        if (this.filters[k]) {
          params[k] = this.filters[k];
        }
      });

      if (this.queries.keyword) {
        params.keyword = this.queries.keyword;
      }

      if (this.queries.not_before) {
        params.not_before = this.queries.not_before;
      }

      if (this.queries.not_after) {
        params.not_after = this.queries.not_after;
      }

      if (this.queries.not_before_ymd) {
        params.not_before_ymd = this.queries.not_before_ymd;
      }

      if (this.queries.not_after_ymd) {
        params.not_after_ymd = this.queries.not_after_ymd;
      }

      return params;
    },
    syncRouterState(facetState) {
      const query = {
        facets: facetState,
      };
      if (!this.sqsFlagSet) {
        query.use_sqs = this.useSqs;
      }
      if (this.$route.query.initial) {
        // NOTE: Replacing the initial state instead of pushing it
        // prevents the user from having to invoke History.back() twice
        // to return to /library.
        this.$router.replace({ query });
      } else {
        this.$router.push({ query });
      }
    },
  },
};
</script>
