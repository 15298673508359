import HTTP from './http';
import pagination from './pagination';

export default {
  getTextGroupList: cb => HTTP.get('library/json/').then(r => cb(r.data)),
  getLibraryVector: (urn, params, cb) => HTTP.get(`library/vector/${urn}/`, { params }).then(r => cb(r.data)),
  getCollection: (urn, cb) => HTTP.get(`library/${urn}/json/`).then(r => cb(r.data)),
  getPassage: (params, cb) => {
    const { urn, parallelVersionUrn } = params;
    let url = `library/passage/${urn}/json/`;
    if (parallelVersionUrn) {
      url = `${url}?parallel_version_urn=${parallelVersionUrn}`;
    }
    return HTTP.get(url)
      .then(r => cb({ ...r.data, ...pagination(r) }));
  },
  searchText: (params, cb) => HTTP.get('search/json/', { params }).then(r => cb(r.data)),
  searchFacets: (params, cb) => HTTP.get('search/facets/', { params }).then(r => cb(r.data)),
  facetLookup: (params, cb) => HTTP.get('facets/', { params }).then(r => cb(r.data)),
  searchFacetsATLAS: (params, cb) => HTTP.get('atlas/search/facets/', { params }).then(r => cb(r.data)),
  facetLookupATLAS: (params, cb) => HTTP.get('atlas/facets/', { params }).then(r => cb(r.data)),
  getLibraryInfo: cb => HTTP.get('library/json/info').then(r => cb(r.data)),
  getProducts: () => HTTP.get('products/', { headers: { Accept: 'application/json' } }).then(r => r.data),
};
