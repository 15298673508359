<template>
  <div v-if="versions">
    <div class="btn-group btn-group-left">
      <button
        type="button"
        class="btn btn-light dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="sr-only">
          Toggle Dropdown
        </span>
      </button>
      <div class="version-select">
        <slot />
      </div>
      <div :class="['version-option', 'dropdown-menu']">
        <span class="dropdown-header">
          <p>Primary:</p>
          <div>
            <div class="label primary-label">
              <access-icon :access="leftText.metadata.access" />
              {{ leftText.metadata.label }}
            </div>
            <div class="metadata">
              {{ leftText.metadata.human_lang }} {{ leftText.metadata.kind }}
            </div>
          </div>
        </span>
        <div class="dropdown-divider" />
        <span class="dropdown-header d-flex flex-row justify-content-between">
          <div>Secondary:</div>
          <div
            v-if="remove"
            class="flex-column justify-content-between"
          >
            <span
              class="action-item swap"
              title="Swap primary and secondary versions"
              @click.prevent="handleSwap"
            >
              swap versions
            </span>
            <span
              class="action-item remove"
              title="Remove secondary version"
              @click.prevent="handleRemoveClick"
            >
              remove secondary version
            </span>
          </div>
        </span>
        <span
          v-for="version in versions"
          :key="version.urn"
          class="dropdown-item"
          :class="{'disabled': isVersionActive(version)}"
          @click.prevent="handleItemClick(version)"
        >
          <div>
            <div
              class="label secondary-label"
              :class="{ active: isVersionActive(version) }"
            >
              <access-icon :access="version.access" />
              {{ version.label }}
              <span v-if="!version.matchesPrimaryCitationScheme">
                <icon name="exclamation-circle" />
              </span>
            </div>
            <div
              v-if="showDescription"
              class="description"
              v-html="version.description"
            />
            <div class="metadata">
              {{ version.humanLang }} {{ version.kind }}
            </div>
          </div>
        </span>
        <div class="dropdown-divider" />
        <span class="dropdown-item disabled muted small">
          <icon name="exclamation-circle" /> indicates the text will not be aligned
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import URN from '@/js/urn.js';

export default {
  computed: {
    leftText() {
      return this.$store.state.reader.leftText;
    },
    rightText() {
      return this.$store.state.reader.rightText;
    },
    rightPassage() {
      return this.$store.state.reader.rightPassage;
    },
    passage() {
      return this.$store.getters['reader/passage'];
    },
    secondaryUrn() {
      const parallelVersionUrn = this.$route.query.parallel_version_urn;
      if (parallelVersionUrn) {
        return new URN(parallelVersionUrn);
      }
      if (this.rightPassage) {
        return this.rightPassage.urn;
      }
      return null;
    },
    remove() {
      return this.secondaryUrn;
    },
    urn() {
      return this.passage
        ? {
          absolute: `${this.passage.urn}`,
        }
        : null;
    },
    showDescription() {
      // NOTE: Description on Jacoby entries;
      // Revisit if we use this component elsewhere.
      return this.passage.urn.textGroup !== 'fgrh';
    },
  },
  methods: {
    updateRouter(versionUrn) {
      const query = {
        ...this.$route.query,
      };
      query.parallel_version_urn = versionUrn;

      if (query.right) {
        query.right = undefined;
      }

      this.$router.push({ query });
    },
    handleItemClick(version) {
      const { urn, matchesPrimaryCitationScheme } = version;
      if (matchesPrimaryCitationScheme) {
        this.updateRouter(urn);
      } else {
        const query = {
          ...this.$route.query,
        };
        query.parallel_version_urn = undefined;
        query.right = new URN(urn).version;
        this.$router.push({ query });
      }
    },
    handleRemoveClick() {
      this.updateRouter();
    },
    handleSwap() {
      const query = {
        ...this.$route.query,
      };
      const newPrimaryUrn = new URN(`${this.secondaryUrn.toString()}:${this.passage.urn.reference}`);
      const params = {
        urn: `${newPrimaryUrn}`,
      };
      // NOTE: parallel_version_urn should not contain the passage reference,
      // so we use `upTo` to strip it.
      const newSecondaryVersionUrn = new URN(this.leftText.urn);
      if (query.right) {
        query.right = newSecondaryVersionUrn.version;
      } else {
        query.parallel_version_urn = `${newSecondaryVersionUrn.upTo('version')}:`;
      }
      this.$router.push({ params, query });
    },
    isCurrentVersion(versionUrn) {
      return versionUrn.indexOf(this.leftText.urn) > -1;
    },
    isVersionActive(version) {
      const versionUrn = new URN(version.urn);
      return this.secondaryUrn ? versionUrn.version === this.secondaryUrn.version : false;
    },
  },
  apollo: {
    versions: {
      query: gql`
        query VersionAlignments($urn: String!) {
            versionAlignments(reference: $urn) {
                edges {
                    node {
                        access
                        label
                        humanLang
                        kind
                        description
                        urn
                        matchesPrimaryCitationScheme
                    }
                }
            }
        }
      `,
      variables() {
        return { urn: this.urn.absolute };
      },
      update(data) {
        return data.versionAlignments.edges.map(e => e.node);
      },
      skip() {
        return this.urn === null;
      },
    },
  },
};
</script>

<style scoped lang="scss">
  .description {
    max-width: 100%;
    overflow: clip;
  }
  .dropdown-item, .action-item {
    cursor: pointer;
  }
  .action-item {
    padding-left: 1.0em;
    &:active {
      color: #fff;
    }
    &.swap {
      color: initial;
    }
    &.remove {
      color: #B60E10;
    }
    &:hover {
      font-weight: bold;
    }
  }
  .primary-label {
    $gray-900: #212529;
    color: $gray-900;
  }
  .secondary-label {
    $gray-600: #868e96;
    color: $gray-600;
    &.active {
      $gray-900: #212529;
      color: $gray-900;
    }
  }
  .dropdown-item:hover {
    .secondary-label {
      $gray-900: #212529;
      color: $gray-900;
    }
  }


</style>
