<template>
  <base-widget class="morphology" v-if="enabled">
    <span slot="header">Morphology</span>
    <div slot="body">
      <text-loader v-if="loading" size="7px" margin="1px" />
      <p v-else-if="! langAvailable" class="no-results">The main language of the selected pane under TEXT MODE is not supported by the morphology tool.</p>
      <div class="group" v-else-if="morphBody && morphEngine === 'Morpheus'" v-for="group in morphBody" :key="group.uri">
        <div class="head">
          <span class="hdwd">{{ group.hdwd }}</span>
          <span class="pofs-decl">{{ group.pofs }} {{ group.decl }}</span>
        </div>
        <div class="entries">
          <div class="entry" v-for="entry in group.infl">
            <div class="form">
              <span class="stem">{{ entry.stem }}</span><span v-if="entry.suff" class="suff">-{{ entry.suff }}</span>
            </div>
            <!-- {{ entry.pofs }} -->
            <!-- {{ entry.stemtype }} -->
            <div class="props">
              {{ entry.tense }}
              {{ entry.voice }}
              {{ entry.mood }}
              {{ entry.pers }}
              {{ entry.case }}
              {{ entry.num }}
              {{ entry.gend }}
              {{ entry.comp }}
              <span v-if="entry.dial" class="dial">({{ entry.dial }})</span>
            </div>
            <!-- {{ entry.derivtype }} -->
            <!-- {{ entry.morph }} -->
          </div>
        </div>
      </div>
      <div class="group" v-else-if="morphBody && morphEngine === 'Sedra'" v-for="group in morphBody">
        <div class="head">
          <span class="hdwd" lang="syr-Syre">{{ group.syriac }}</span>
          <span class="pofs-decl">{{ group.category }}</span>
        </div>
        <div class="entries">
          <div class="entry" v-for="entry in group.entries">
            <div class="form">
              <span class="stem" lang="syr-Syre">{{ entry.stem }}</span>
              <template v-if="entry.western">(Western voc.: <span class="stem" lang="syr-Syre">{{ entry.western }}</span>)</template>
              <template v-if="entry.eastern">(Eastern voc.: <span class="stem" lang="syr-Syre">{{ entry.eastern }}</span>)</template>
            </div>
            <div class="props">
              {{ entry.state }} {{ entry.number }} {{ entry.gender }}
            </div>
            <div class="props">
              {{ entry.tense }} {{ entry.person}}
            </div>
            <div class="props">
              {{ entry.kaylo }}
            </div>
            <div class="props" v-if="entry.suffixType">{{ entry.suffixType }}
              ({{ entry.suffixNumber }}
              {{ entry.suffixPerson }}
              {{ entry.suffixGender }})
            </div>
            <div class="gloss" v-if="entry.glosses.eng" v-html="cleanGlosses(entry.glosses.eng)"></div>
          </div>
        </div>
      </div>
      <p v-else-if="selectedWord" class="no-results">No results found.</p>
      <p v-else class="instructions">
        In <span class="mode">highlight</span> text mode, select a word to get morphological analysis.
      </p>
      <p class="reference" v-if="morphBody && !loading">
        Morphological information provided by <a :href="morphEngineURL">{{morphEngine}}</a>
      </p>
    </div>
  </base-widget>
</template>

<script>
import constants from '../../constants';
import TextLoader from '../../components/TextLoader.vue';

export default {
  name: 'widget-morphology',
  watch: {
    selectedWord: {
      handler: 'fetchData',
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      morphBody: null,
      supportedLang: ['grc', 'la', 'syr']
    };
  },
  computed: {
    enabled() {
      let leftLang = false;
      let rightLang = false;
      let parallelLang = false;

      if( this.text ) {
        leftLang = this.supportedLang.includes(this.text.metadata.lang)
      }
      if( this.rightText ) {
        rightLang = this.supportedLang.includes(this.rightText.metadata.lang)
      }
      if( this.parallelText ) {
        parallelLang = this.supportedLang.includes(this.parallelText.metadata.lang)
      }

      return leftLang || rightLang || parallelLang;
    },
    selectedWords2() {
      return this.$store.getters['reader/selectedWords2'];
    },
    selectedWords() {
      return this.$store.getters['reader/selectedWords'];
    },
    selectedWord() {
      if (this.selectedWords2.length === 0) {
        return null;
      }
      return this.selectedWords2[0];
    },
    text() {
      const text = this.$store.getters['reader/text'];
      return text;
    },
    rightText() {
      const text = this.$store.getters['reader/rightText'];
      return text;
    },
    parallelText() {
      const text = this.$store.getters['reader/parallelText'];
      return text;
    },
    textModePane() {
      return this.$store.state.reader.textModePane;
    },
    langAvailable() {
      if( this.textModePane === 'left') {
        return this.supportedLang.includes(this.text.metadata.lang)
      } else {
        if( this.parallelText ) {
          return this.supportedLang.includes(this.parallelText.metadata.lang)
        } else if( this.rightText ) {
          return this.supportedLang.includes(this.rightText.metadata.lang)
        }
      }
    },
    engineSupported() {
      return this.morphEngine === 'Morpheus' || this.morphEngine === 'Sedra';
    },
    baseUrl() {
      return this.$router.options.base;
    },
  },
  methods: {
    fetchData() {
      const word = this.selectedWord;
      let lang;
      if( this.textModePane === "right") {
        if( this.parallelText) {
          lang = this.parallelText.metadata.lang;
        } else {
          lang = this.rightText.metadata.lang;
        }
      } else {
        lang = this.text.metadata.lang;
      }

      if (word && this.supportedLang.includes(lang)) {
        this.loading = true;
        const url = `${this.baseUrl}morph/?word=${word.w}&lang=${lang}`;
        const headers = new Headers({
          Accept: 'application/json',
        });
        fetch(url, { method: 'GET', headers })
            .then((resp) => {
              resp.json()
                  .then((data) => {
                    if (data.Body && data.Body.length > 0) {
                      this.morphBody = data.Body;
                      const lemmas = [];
                      this.morphBody.forEach(({ hdwd }) => {
                        lemmas.push(hdwd);
                      });
                      this.$store.commit(`reader/${constants.READER_SET_SELECTED_LEMMAS}`, { lemmas });
                      this.morphEngine = data.Engine;
                      this.morphEngineURL = data.EngineURL;
                    } else {
                      this.reset();
                    }
                    this.loading = false;
                  })
                  .catch( err => {
                    this.reset();
                    this.loading = false;
                  });
            });
      } else {
        this.reset();
      }
    },
    reset() {
      this.morphBody = null;
      this.$store.commit(`reader/${constants.READER_SET_SELECTED_LEMMAS}`, { lemmas: null });
    },
    cleanGlosses(glosses) {
      return this.$sanitize(
        glosses.join(' / '),
        {allowedTags: ['span'], allowedAttributes: {'span': ['class', 'lang']}}
      );
    },
  },
  components: {
    TextLoader,
  },
};
</script>
