export default {
  textMode: 'browser',
  textModePane: 'left',
  sidebarLeftOpened: true,
  sidebarRightOpened: true,
  versions: null,
  leftText: null,
  rightText: null,
  parallelText: null,
  lowerText: null,
  leftPassage: null,
  rightPassage: null,
  parallelPassage: null,
  lowerPassage: null,
  leftPassageText: null,
  rightPassageText: null,
  parallelPassageText: null,
  lowerPassageText: null,
  highlight: null,
  annotations: new Map(),
  annotationsHash: {},
  annotationChange: 0,
  selectedLemmas: null,
  availableRefs: [],
  selectedRefRange: { start: null, end: null },
  error: '',
  selectedTokenRange: { start: null, end: null },
};
