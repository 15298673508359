const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_TITLE = 'title';
const SORT_RELEVANCE = 'relevance';

const DSS_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_TITLE,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_TITLE]: {
        label: 'Entry',
        value: ['dss_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_TITLE) {
      return ['dss_work_label.keyword'];
    } if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_TITLE;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.dss_entry_urn[0]),
  queries: {
    keyword: '',
    contents: '',
    inventorys: '',
    translations: '',
    provenances: '',
    photographs: '',
    publications: '',
    products: '',
    work_labels: '',
  },
  lookups: {
    contents: [],
    inventorys: [],
    translations: [],
    provenances: [],
    photographs: [],
    publications: [],
    products: [],
    work_labels: [],

  },
  filters: {
    keyword: false,
    contents: null,
    inventorys: null,
    translations: null,
    provenances: null,
    photographs: null,
    publications: null,
    products: null,
    work_labels: null,
  },
  facets: [
    {
      label: 'Content',
      value: 'contents',
      toolTipText: 'Filter by content',
      placeholder: '',
    },
    {
      label: 'Inventory',
      value: 'inventorys',
      toolTipText: 'Filter by inventory',
      placeholder: '',
    },
    {
      label: 'Translation',
      value: 'translations',
      toolTipText: 'Filter by translation',
      placeholder: '',
    },
    {
      label: 'Provenance',
      value: 'provenances',
      toolTipText: 'Filter by provenance',
      placeholder: '',
    },
    {
      label: 'Photographs',
      value: 'photographs',
      flatten: true,
      multiple: true,
      toolTipText: 'Search for a specific photograph',
      placeholder: '',
    },
    {
      label: 'Publication',
      value: 'publications',
      toolTipText: 'Filter by publication',
      placeholder: '',
    },
    {
      label: 'Product',
      value: 'products',
      toolTipText: 'Filter by product',
      placeholder: '',
    },
    {
      label: 'Work',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: '',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default DSS_SCHEMA;
