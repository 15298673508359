<template>
  <div>
    <div
      v-if="minValue"
      class="btn btn-sm btn-primary mr-2"
      @click="$emit('reset', field.min.value)"
    >
      {{ field.min.badgeLabel }} {{ minValue }} <span
        class="badge"
      >
        <span class="fa fa-times" />
      </span>
    </div>
    <div
      v-if="maxValue"
      class="btn btn-sm btn-primary mr-2"
      @click="$emit('reset', field.max.value)"
    >
      {{ field.max.badgeLabel }} {{ maxValue }} <span
        class="badge"
      >
        <span class="fa fa-times" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['minValue', 'maxValue', 'field'],
};
</script>
