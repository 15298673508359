export const loadCounter = () => {
  window._ll = window._ll || []; // eslint-disable-line no-underscore-dangle

  const lls = document.createElement('script');
  lls.type = 'text/javascript';
  lls.async = true;
  lls.src = '//connect.liblynx.com/log/js/counter5.min.js';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(lls, s);
};

const track = (type, payload) => {
  console.log(`=== track ${type}, ${JSON.stringify(payload)}`);
  if (!window.LIBLYNX_COUNTER_CONFIG_PID) {
    console.log('Liblynx Counter logging disabled');
    return;
  }

  Promise.resolve(payload).then((payload_) => {
    // eslint-disable-next-line no-underscore-dangle
    window._ll.push([`track${type}`, payload_]);
  });
};

const install = (Vue) => {
  Vue.prototype.$counter = Vue.prototype.$counter || {};
  Vue.prototype.$counter = {
    ...Vue.prototype.$counter,
    track,
  };
};

const CounterPlugin = { install };

export default CounterPlugin;
