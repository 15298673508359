import './directives';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { sync } from 'vuex-router-sync';
import VueSanitize from 'vue-sanitize';
import VueVirtualScroller from 'vue-virtual-scroller';


import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { SkeletonPlugin } from '@scaife-viewer/skeleton';
import { iconMap as commonIconMap } from '@scaife-viewer/common';
import BootstrapVue from 'bootstrap-vue';
import { GridGlobal } from 'gridjs-vue';

// TODO: Prevent iconMap from being shadowed in components/icons.js
import { iconMap as deIconMap } from '@scaife-viewer/widget-dictionary-entries';
import store from './store';
import router from './router';
import CounterPlugin from './plugins/counter';
import App from './App.vue';
import GraphQLPlugin, { apolloProvider } from './atlas/gql';

import globalComponents from './components';

Vue.config.devtools = process.env.NODE_ENV === 'development';

Vue.config.productionTip = false;

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
});

export default () => {
  if (document.getElementById('app')) {
    globalComponents.forEach(component => Vue.component(component.name, component));
    Vue.use(BootstrapVue);
    Vue.use(GridGlobal);

    sync(store, router);

    // TODO: Retire GraphQLPlugin in favor of ApolloProvider
    Vue.use(GraphQLPlugin);
    Vue.use(VueApollo);
    // adds a $counter object on each Vue instance that has a track() method
    Vue.use(CounterPlugin, {});
    Vue.use(VueSanitize);
    Vue.use(VueVirtualScroller);
    Vue.use(SkeletonPlugin, {
      iconMap: {
        ...commonIconMap,
        ...deIconMap,
      },
      config: {
        // NOTE: $scaife.citationSchemeMap is used to map
        // citation scheme kinds to a pluralized, human-friendly
        // label.
        search: {
          lemmasEnabled: false,
        },
        citationSchemeMap: {
          column: 'Columns',
          fragment: 'Fragments',
          'rome page': 'Rome Pages',
          book: 'Books',
          entry_part: 'Entry Parts',
          chapter: 'Chapters',
          hand: 'Hands',
          line: 'Lines',
          page: 'Pages',
          saying: 'Sayings',
          section: ' Sections',
          volume: 'Volumes',
          work: 'Works',
        },
        dictionaryEntries: {
          showCitationResolutionHint: false,
          resolveUsingNormalizedLemmas: true,
          selectDictionaries: false,
        },
      },
    });


    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      render: h => h(App),
      store,
      router,
      apolloProvider,
    });
  }
};
