<template>
  <base-widget class="passage-exports">
    <span slot="header">Export Passage</span>
    <div slot="body">
      <template v-if="rightPassage">
        <PassageExport
          :side = "'left'"
          :passage = {...leftPassage}
          :text = {...leftText}
        />
        <PassageExport
          :side = "'right'"
          :passage = {...rightPassage}
          :text = {...rightText}
        />
      </template>
       <template v-else-if="isParallel">
         <PassageExport
          :side = "'left'"
          :passage = {...leftPassage}
          :text = {...leftText}
        />
         <PassageExport
          :side = "'right'"
          :passage = {...parallelPassage}
          :text = {...parallelText}
        />
      </template>
      <template v-else>
         <PassageExport
          :side = "''"
          :passage = {...passage}
          :text = {...passageText}
        />
      </template>
    </div>
  </base-widget>
</template>

<script>
import PassageExport from "@/js/library/PassageExport.vue";
import URN from "@/js/urn";

export default {
  name: "widget-passage-exports",
  components: {PassageExport},
  computed: {
    passage() {
      return this.$store.getters["reader/passage"];
    },
    passageText() {
      let textPart;

      if( this.leftText ) {
        textPart = this.leftText;
      }
      else if( this.rightText ) {
        textPart = this.rightText;
      }
      return textPart;
    },
    leftPassage() {
      return this.$store.state.reader.leftPassage;
    },
    leftText() {
      return this.$store.state.reader.leftText;
    },
    rightPassage() {
      return this.$store.state.reader.rightPassage;
    },
    rightText() {
      return this.$store.state.reader.rightText;
    },
    isParallel() {
      return this.$store.state.reader.parallelPassage;
    },
    parallelPassageUrn() {
      const parPassage = this.$store.state.reader.parallelPassage;
      const availableLeftRefs = Object.values(this.$store.state.reader.availableRefs || {});
      const availableParallelRefs = Object.keys(this.leftPassage.metadata.parallel_passage_lu || {});

       const matchingRefs = availableParallelRefs.filter((rItem) => {
          return availableLeftRefs.find(function(lItem) {
            return rItem === lItem
          })
       })

      let reference = '';
      if( matchingRefs.length === 1 ) {
        reference = matchingRefs[0];
      } else if( matchingRefs.length >= 2 ) {
        reference = matchingRefs[0] + "-" + matchingRefs[matchingRefs.length - 1];
      }

      if( reference !== '' ) {
        return new URN(parPassage.urn.upTo('version') + ":" + reference);
      } else {
        return '';
      }
    },
    parallelPassage() {
      const parPassage = {...this.$store.state.reader.parallelPassage}

      if( this.parallelPassageUrn !== '') {
        parPassage.urn = this.parallelPassageUrn;
        return parPassage;
      } else {
        return undefined
      }
    },
    parallelText() {
      return this.$store.state.reader.parallelText;
    },
  }
};
</script>
