import constants from '@/js/constants';

import ProductSearchResultDetail from '../ProductSearchResultDetail.vue';
import WorkSearchResultDetail from '../WorkSearchResultDetail.vue';
import VersionSearchResultDetail from '../VersionSearchResultDetail.vue';


const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_CANONICAL = 'canonical';
const SORT_WORK_LABEL = 'work_label';
const SORT_RELEVANCE = 'relevance';

const UNIVERSAL_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_CANONICAL,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_CANONICAL]: {
        label: 'Library Order',
        value: ['se_product_name.keyword', 'canonical_sort'],
      },
      [SORT_WORK_LABEL]: {
        label: 'Work Name',
        value: ['se_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_CANONICAL) {
      return ['se_product_name.keyword', 'canonical_sort.keyword'];
    } if ($vm.sort === SORT_WORK_LABEL) {
      return ['se_work_label.keyword'];
    } if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_CANONICAL;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.se_product_reference[0]),
  workResultKeyFunc: (result => result.work[0]),
  versionResultKeyFunc: (result => result['text.urn'][0]),
  queries: {
    keyword: '',
    product_names: '',
    text_group_labels: '',
    work_labels: '',
    version_labels: '',
    version_kinds: '',
    version_languages: '',
  },
  lookups: {
    product_names: [],
    text_group_labels: [],
    work_labels: [],
    version_labels: [],
    version_kinds: [],
    version_languages: [],
  },
  filters: {
    keyword: false,
    product_names: null,
    text_group_labels: null,
    work_labels: null,
    version_labels: null,
    version_kinds: null,
    version_languages: null,
  },
  facets: [
    {
      kind: 'operatorField',
      label: constants.PRODUCT_LABEL,
      value: 'product_names',
      flatten: true,
      multiple: true,
      operator: true,
      hideOperatorToggle: true,
      toolTipText: `Filter by ${constants.PRODUCT_LABEL.toLowerCase()}`,
      placeholder: '',
    },
    {
      label: 'Text Group',
      value: 'text_group_labels',
      toolTipText: 'Filter by text group',
      placeholder: '',
    },
    {
      label: 'Work',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: '',
    },
    {
      label: 'Version',
      value: 'version_labels',
      toolTipText: 'Filter by version',
      placeholder: '',
    },
    {
      label: 'Category',
      value: 'version_kinds',
      toolTipText: 'Filter by category (edition, translation, commentary, etc.)',
      placeholder: '',
    },
    {
      label: 'Language',
      value: 'version_languages',
      toolTipText: 'Filter by version language',
      placeholder: '',
    },
  ],
  lenses: {
    coarse: {
      label: constants.PRODUCT_LABEL.toLowerCase(),
      pluralLabel: constants.PRODUCT_LABEL_PLURAL.toLowerCase(),
      kind: LENS_COARSE,
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
      ]),
    },
    work: {
      label: 'work',
      pluralLabel: 'works',
      kind: 'work',
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
        SORT_WORK_LABEL,
      ]),
    },
    version: {
      label: 'version',
      pluralLabel: 'versions',
      kind: 'version',
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
        SORT_WORK_LABEL,
      ]),
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
        SORT_WORK_LABEL,
      ]),
    },
  },
  versionLensComponent: VersionSearchResultDetail,
  workLensComponent: WorkSearchResultDetail,
  coarseLensComponent: ProductSearchResultDetail,
};

export default UNIVERSAL_SCHEMA;
