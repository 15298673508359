<script>
import {MetaSearch} from "@/js/tracking";

export default {
  data() {
    return {
      llCounted: false, // Liblynx counted
    }
  },
  methods: {
    liblynxResetCounted: function() {
      this.llCounted = false;
    },
    /**
     * @param source = global OR publication
     */
    libLynxCountSearch: function(source) {

      // We only want to count a view once.
      if( this.llCounted ) {
        return;
      }

      // Only count when on the first page
      if( this.currentPage === 1) {

        // We need a filter or query
        let hasFilter = false;
        let hasQuery = false;

        for (const [key, value] of Object.entries(this.filters)) {
          if( value ) { hasFilter = true; }
        }

        for (const [key, value] of Object.entries(this.queries)) {
          if( value ) { hasQuery = true; }
        }

        if(hasFilter || hasQuery) {
          this.llCounted = true;
          const payload = MetaSearch( this.meta, source );
          this.$counter.track('Search', payload);
        }
      }
    }
  }
}
</script>