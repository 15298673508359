<template>
  <div class="version-list">
    <template v-if="loading">
      <div class="ball-pulse partial-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </template>
    <div v-else-if="error" class="text-center"><b>Error</b>: {{ error }}</div>
    <div v-else>
      <h2>Versions</h2>
      <div class="card-deck">
        <div class="version-card" v-for="text in versions" :key="text.urn">
          <div class="card-body">
            <p class="text-subtype">{{ text.kind }}</p>
            <h4 class="card-title"><a :href="getReaderURL(text)">{{ text.label }}</a></h4>
            <p class="card-text">{{ text.description }}</p>
          </div>
          <div class="card-footer">
            <a :href="getReaderURL(text)"><icon name="book"></icon> Read ({{ text.humanLang }})</a>
            <span v-if="text.access"><i class="fas fa-unlock access-granted-lock"></i></span>
            <span v-else><i class="fas fa-lock access-denied-lock"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
// import constants from '../constants';
import Skeleton2NavigationMixin from '../mixins/Skeleton2NavigationMixin.vue';

export default {
  name: 'cts-version-list',
  mixins: [Skeleton2NavigationMixin],
  created() {
    // @@@ add loading / error handling to GraphQLPlugin
    // this.loading = true;
    // this.$store.dispatch(constants.LIBRARY_LOAD_VERSION_LIST, this.$route.params.urn)
    //   .then(() => {
    //     this.loading = false;
    //   })
    //   .catch((err) => {
    //     this.loading = false;
    //     this.error = err.message;
    //     throw err;
    //   });
  },
  data() {
    return {
      // loading: false,
      error: '',
    };
  },
  computed: {
    loading() {
      return !this.gqlData;
    },
    urn() {
      return `${this.$route.params.urn}`;
    },
    filterableWorkUrn() {
      return `${this.urn}.`;
    },
    versions() {
      return this.gqlData
        ? this.gqlData.versions.edges.map(version => version.node)
        : [];
    },
    gqlQuery() {
      return gql`
        {
          versions (urn_Startswith:"${this.filterableWorkUrn}") {
            edges{
              node {
                urn
                description
                kind
                label
                humanLang
                access
              }
            }
          }
        }`;
    },
  },
};
</script>
