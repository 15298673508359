<template>
  <div class="passage-cannot-render-tokens-alert" role="alert">
    Due to the number of words in this passage, some features have been disabled
  </div>
</template>
<script>
export default {
  name: 'passage-cannot-render-tokens',
}
</script>
