<template>
  <div class="operator-container">
    Operator:
    <span class="float-right">
      <label>
        AND
        <input
          v-model="localValue"
          type="radio"
          :name="`${label}_operator`"
          value="AND"
        >
      </label>
      <label>
        OR
        <input
          v-model="localValue"
          type="radio"
          :name="`${label}_operator`"
          value="OR"
        >
      </label>
    </span>
  </div>
</template>
<script>

export default {
  props: ['value', 'label'],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
