
<!-- Right click (contextmenu) has been disabled to make sure exports are counted in Liblynx -->
<template>
  <p>
    <span class="side" v-if="side">{{sideLabel}}</span>
    <span class="links" v-if="passage.urn">
      <a :href="getPassageUrl(passage, 'text')" @click="(e) => trackItemRequest(e, passage,side)" @contextmenu.prevent="">text</a>,
      <a :href="getPassageUrl(passage, 'xml')" @click="(e) => trackItemRequest(e, passage, side)" @contextmenu.prevent="">xml</a>
      or <a :href="getPassageUrl(passage, 'source')" @click="(e) => trackItemRequest(e, passage,side)" @contextmenu.prevent="">source</a>.
    </span>
    <span v-if="! passage.urn">
      - Not available -
    </span>
  </p>
</template>

<script>

import {TextPartRequest} from "@/js/tracking";

export default {
  name: "passage-export",
  props: {
    side: {
      type: String,
      default: ''
    },
    passage: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    sideLabel() {
      if( this.$props.side === 'left' ) {
        return "L";
      } else if( this.$props.side === 'right' || this.$props.side === 'parallel' ) {
        return "R";
      }
      return '';
    },
  },
  methods: {
    getPassageUrl(passage, format) {
      const baseURL = this.$router.options.base;
      return `${baseURL}library/passage/${passage.urn.toString()}/${format}/`;
    },
    trackItemRequest(evt, passage, side) {
      // When an item is exported, it is counted as a ItemRequest in Liblynx.
      const textPart = this.$props.text;

      if (!textPart) {
        // eslint-disable-next-line no-console
        console.error(`Untrackable text part: ${this}`);
        return;
      }
      const urnObject = passage.urn
      this.$counter.track('Request', TextPartRequest({ urn: urnObject.value, ...textPart }));
    }
  }
}
</script>