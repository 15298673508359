import { render, staticRenderFns } from "./WidgetTextWidth.vue?vue&type=template&id=720bf1e4&"
import script from "./WidgetTextWidth.vue?vue&type=script&lang=js&"
export * from "./WidgetTextWidth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "WidgetTextWidth.vue"
export default component.exports