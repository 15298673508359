<template>
  <span v-if="urn.includes('stoa0023.stoa001')"><router-link class="ref-lower" :to="toPassage(urn)"><span><slot></slot></span></router-link></span>
  <span v-else-if="urn.includes('urn:cts:')">
    <a class="ref-lower" :href="`${this.$router.options.base}reader/${urn}`"><span><slot></slot></span></a>
  </span>
  <span v-else-if="urn == 'placeholder'"><slot></slot></span>
  <span v-else><a class='ref-lower' :href="`${this.$router.options.base}reader/${urn}/`"><span><slot></slot></span></a></span>
</template>

<script>
import ReaderNavigationMixin from '../mixins/ReaderNavigationMixin.vue';

export default {
  name: 'RefLower',
  mixins: [
    ReaderNavigationMixin,
  ],
  props: {
    urn: {
      type: String,
    },
  },
};
</script>
