<template>
  <div class="btn-group btn-group-left">
    <button
      type="button"
      class="btn btn-light dropdown-toggle dropdown-toggle-split"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <div class="version-select">
      <slot></slot>
    </div>
    <div :class="['version-option', 'dropdown-menu']">
      <router-link v-for="version in versions" :key="version.urn" class="dropdown-item" :to="to(version.urn)">
        <template v-if="rightPassage">
          <span v-if="version.urn === leftText.urn" class="side">L</span>
          <span v-if="rightText && version.urn === rightText.urn" class="side">R</span>
        </template>
        <template v-else>
          <span v-if="version.urn === leftText.urn" class="side">&nbsp;</span>
        </template>
        <div>
          <div class="label">
            <access-icon :access="version.access" />
            {{ version.label }}
          </div>
          <div
            class="description"
            v-html="version.description"
          />
          <div v-if="showVersionKind" class="metadata">{{ version.human_lang }} {{ version.kind }}</div>

        </div>
      </router-link>
      <template v-if="remove">
        <div class="dropdown-divider"></div>
        <router-link class="dropdown-item remove" :to="remove()">remove column</router-link>
      </template>
    </div>
  </div>
</template>

<script>
import URN from "@scaife-viewer/common";

export default {
  props: ['versions', 'to', 'remove'],
  computed: {
    showVersionKind() {
      return !this.leftText.urn.includes('reltex:vipo');
    },
    leftText() {
      return this.$store.state.reader.leftText;
    },
    rightText() {
      return this.$store.state.reader.rightText;
    },
    rightPassage() {
      return this.$store.state.reader.rightPassage;
    },
  },
};
</script>

<style scoped lang="scss">
  .description {
    overflow: clip;
  }
</style>
