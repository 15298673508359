<template>
  <a
    v-popover:bottom="{title: text.label, content: text.description, trigger: 'hover'}"
    class="text-version badge badge-light"
    :href="getReaderURL(text)"
    :class="accessClass"
  >
    {{ text.lang }}
  </a>
</template>

<script>
import Skeleton2NavigationMixin from '../mixins/Skeleton2NavigationMixin.vue';

export default {
  mixins: [Skeleton2NavigationMixin],
  props: ['text'],
  computed: {
    accessClass() {
      return this.text.access ? 'access-granted' : 'access-denied';
    },
  },
};
</script>

<style lang="scss">
  .text-version.badge {
      display: inline-block;
      margin-right: 5px;
  }
</style>
