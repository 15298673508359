<template>
  <div
    class="empty-parallel-text-part-node"
  >
    <div class="message">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyParallelTextPartNode',
};
</script>

<style lang="scss" scoped>
.empty-parallel-text-part-node {
  display: flex;
  align-items: center;
  .message {
    width: 100%;
    color: rgba(178, 178, 177, 1.0);
    text-align: center;
    font-size: 12px;
    font-family: var(
        --sv-empty-parallel-text-part-node-font-family,
        "Noto Sans",
        "Noto Sans Coptic"
    );
  }
}
</style>
