export default {
  textGroups: [],
  textGroupWorks: [],
  textGroupTexts: [],
  textGroupUrns: {},
  allTextGroups: null,
  allTextGroupWorks: null,
  allTextGroupTexts: null,
  sortKind: 'text-group',
  works: [],
  versions: [],
  allWorks: null,
  toc: [],
};
