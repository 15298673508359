<template>
  <div>
    <embeddable-plot v-if="type === 'plot'" :url="url" :brill-image="brillImage" />
    <embeddable-grid v-if="type === 'grid'" :url="url" :brill-image="brillImage" />
  </div>
</template>

<script>
import EmbeddableGrid from './EmbeddableGrid.vue';
import EmbeddablePlot from './EmbeddablePlot.vue';
import favicon from '@/images/favicon/favicon-32x32.png';

export default {
  name: 'Embeddable',
  components: {
    EmbeddablePlot,
    EmbeddableGrid,
  },
  props: {
    type: { type: String, default: null },
    url: { type: String, default: null },
  },
  methods: {
    brillImageUrl() {
      // FIXME: Figure out a better way to handle
      // publicPath resolution in conjunction with
      // django_webpack
      if (favicon.startsWith('http')) {
        return favicon;
      }
      const STATIC_URL = `${this.$router.options.base}site_media/static/`;
      return `${STATIC_URL}${favicon}`;
    },
  },
  created() {
    this.brillImage = this.brillImageUrl();
  },
};
</script>
