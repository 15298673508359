 <template>
  <base-widget v-if="scaifeConfig.show" :key="`${passage.urn}`" class="concordance">
    <span
      slot="header"
    >
      {{ scaifeConfig.displayName }}
    </span>
    <div slot="body">
      <EmptyMessage v-if="!entries || Object.keys(entries).length === 0">
        No entries found.
      </EmptyMessage>
      <table v-else>
        <tr v-for="(fragEntries, fragment) in entries" class="entry">
          <td>
            <span class="fragment-label">{{ fragment }}</span>
          </td>
          <td>
            <ul class="list-unstyled">
              <li v-for="entry in fragEntries">
                <router-link :to="toPassage(entry.passageUrn)">
                  {{ entry.label }}
                </router-link>
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  </base-widget>
</template>

<script>
import gql from "graphql-tag";
import { EmptyMessage } from "@scaife-viewer/common";

import ReaderNavigationMixin from "../../mixins/ReaderNavigationMixin.vue";
import URN from '../../urn';

var groupByWork = function(urnList) {
  return urnList.reduce(function (r, a) {
    const fragment = new URN(a.ref).reference.split(".")[0];
    r[fragment] = r[fragment] || [];
    r[fragment].push(a);
    return r;
  }, {});
};

export default {
  name: "widget-concordance-entries",
  components: {
    EmptyMessage,
  },
  mixins: [
    // NOTE: This would be replaced by a `ReaderLink` component in SV 2
    ReaderNavigationMixin,
  ],

  data() {
    return {
      // NOTE: This information would move to the scaifeConfig singleoton in SV2
      scaifeConfig: {
        displayName: "Concordance",
        location: "sidebar",
        singleton: true,
        show: false,
      },
    };
  },
  computed: {
    passage() {
      return this.$store.getters["reader/passage"];
    },
    // NOTE: Prefer SV 2 urn computed property
    urn() {
      return this.passage
        ? {
            absolute: `${this.passage.urn}`,
          }
        : null;
    },
  },
  watch: {
    // NOTE: This would be replaced by "widget contract"
    // functionality in SV 2
    passage: {
      immediate: true,
      handler() {
        if (this.passage.urn.textGroup === "dss") {
          this.scaifeConfig.show = true;
        } else {
          this.scaifeConfig.show = false;
        }
      },
    },
  },
  apollo: {
    entries: {
      query: gql`
        query Concordances($urn: String!) {
          concordanceEntries(reference: $urn) {
            edges {
              node {
                id
                idx
                data
              }
            }
          }
        }
      `,
      variables() {
        return { urn: this.urn.absolute };
      },
      update(data) {
        const annotations = data.concordanceEntries.edges.map((e) => {
          return {
            idx: e.node.idx,
            label: e.node.data.label,
            passageUrn: e.node.data.urn,
            ref: e.node.data.reference,
          };
        });
        return groupByWork(annotations);
      },
      skip() {
        return this.urn === null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.entry {
  // NOTE: CSS variables would be used to customize fonts under SV 2
  font-family: var(
    --sv-widget-concordance-entries-entry-font-family,
    "Noto Sans",
    "Noto Sans Coptic"
  );
}
</style>
