<template>
  <div
    class="col-lg-8 offset-lg-2 payload-cleaned-alert"
    role="alert"
  >
    We were unable to load the previously selected filters.
  </div>
</template>
<script>
export default {
  name: 'PayloadCleanedNotice',
};
</script>
