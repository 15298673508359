const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_TITLE = 'title';
const SORT_RELEVANCE = 'relevance';
const SORT_ENTRY_NUMBER = 'entryNumber';

const JO_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_ENTRY_NUMBER,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_TITLE]: {
        label: 'Title',
        value: ['bnjo_work_title.keyword'],
      },
      [SORT_ENTRY_NUMBER]: {
        label: 'Entry No',
        value: ['bnjo_work_number.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_TITLE) {
      return ['bnjo_work_title.keyword'];
    } if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    } if ($vm.sort === SORT_ENTRY_NUMBER) {
      return ['bnjo_work_number.keyword'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_ENTRY_NUMBER;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.bnjo_entry_urn[0]),
  queries: {
    keyword: '',
    authors: '',
    parts: '',
    jacoby_editions: '',
    work_labels: '',
    subjects: '',
    version_kinds: '',
  },
  lookups: {
    authors: [],
    parts: [],
    jacoby_editions: [],
    work_labels: [],
    subjects: [],
    version_kinds: [],
  },
  filters: {
    keyword: false,
    authors: null,
    parts: null,
    jacoby_editions: null,
    work_labels: null,
    subjects: null,
    version_kinds: null,
  },
  facets: [
    {
      label: 'Contributor',
      value: 'authors',
      toolTipText: 'Filter by author',
      placeholder: '',
    },
    {
      label: 'Part',
      value: 'parts',
      toolTipText: 'Search for a part',
      placeholder: '',
    },
    {
      label: 'Edition',
      value: 'jacoby_editions',
      toolTipText: 'Filter by edition',
      placeholder: '',
    },
    {
      label: 'Ancient authors/works',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: '',
    },
    {
      label: 'Subjects',
      value: 'subjects',
      flatten: true,
      multiple: true,
      toolTipText: 'Search for a specific subject',
      placeholder: '',
    },
    {
      label: 'Category',
      value: 'version_kinds',
      flatten: true,
      multiple: true,
      toolTipText: 'Filter by category (edition, translation, commentary, etc.)',
      placeholder: '',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default JO_SCHEMA;
