<template>
  <span>{{ humanizedNumber }}</span>
</template>

<script>
export default {
  props: ['value', 'isMillion'],
  computed: {
    humanizedNumber() {
      let numericValue = parseInt(this.value, 10);
      if (numericValue > 999999 && this.isMillion) {
        numericValue = (numericValue / 1000000).toFixed(1);
      }
      return numericValue.toLocaleString(navigator.language || 'nl');
    },
  },
};
</script>
