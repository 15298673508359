<template>
  <base-widget>
    <span
      slot="header"
    >
      {{ scaifeWidgetTitle }}
    </span>
    <div slot="body">
      <passage-citation-TOC-widget />
    </div>
  </base-widget>
</template>

<script>
import PassageCitationTOCWidget from '@scaife-viewer/widget-passage-citation-toc';
import { displayNameCallback } from '@scaife-viewer/widget-passage-citation-toc';

export default {
  name: 'WidgetPassageCitationTOC',
  components: {
    PassageCitationTOCWidget,
  },
  computed: {
    scaifeWidgetTitle() {
      return displayNameCallback(this.$store.getters, this.$scaife);
    },
  },
};
</script>

<style lang="scss">
  // TODO: Remove widget customizations when using SV 2 Skeleton
  .passage-citation-toc-widget {
    margin: 0;
  }
</style>
