<template>
  <div class="ball-grid-pulse page-loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
    name: 'page-loader'
};
</script>
