const baseURL = () => {
  let value = null;
  const appRoot = document.querySelectorAll('[data-app-root]');
  if (appRoot.length > 0) {
    value = appRoot[0].getAttribute('data-app-root');
  }
  return value || '/';
};

// eslint-disable-next-line import/prefer-default-export
export { baseURL as getBaseURL };
