/* global $ */
import Popper from 'popper.js';
import '@/scss/index.scss';
import '@/images/home_hero.png';
import '@/images/brill_trans.png';
import { loadCounter } from './plugins/counter';
import loadApp from './app';

// for the bootstrap
global.Popper = Popper;
require('bootstrap');

// FIXME: Remove this after all product templates
// have been updated to remove iiif.arkyves.org

const ARKYVES_IIIF_ENDPOINT_URL = 'https://iiif.arkyves.org/';
const IIIF_ENDPOINT_URL = 'https://iiif.brill.com/';


$(() => {
  loadApp();
  loadCounter();

  // Topbar active tab support
  $('.topbar li').removeClass('active');

  const classList = $('body').attr('class').split(/\s+/);
  $.each(classList, (index, item) => {
    const selector = `ul.nav li#tab_${item}`;
    $(selector).addClass('active');
  });

  $('#account_logout, .account_logout').click((e) => {
    e.preventDefault();
    $('#accountLogOutForm').submit();
  });

  $('[data-toggle="tooltip"]').tooltip();
  $(`img[src^='${ARKYVES_IIIF_ENDPOINT_URL}']`).each((idx, elem) => {
    elem.src = elem.src.replace(ARKYVES_IIIF_ENDPOINT_URL, IIIF_ENDPOINT_URL);
  });

  const setupStickySideNav = () => {
    function setSidebarHeight(textContent, toc) {
      const rectTextContent = textContent.getBoundingClientRect();
      const viewPortHeight = (window.innerHeight || document.documentElement.clientHeight);
      toc.style.top = `${Math.max(0, rectTextContent.top)}px`;
      toc.style.bottom = `${Math.max(0, viewPortHeight - rectTextContent.bottom)}px`;
    }
    if ($('#stickyToc').length > 0) {
      const toc = document.getElementById('stickyToc');
      const textContent = document.getElementById('textContent');
      setSidebarHeight(textContent, toc); // initialize
      window.onscroll = function () { setSidebarHeight(textContent, toc); };
    }
  };
  setupStickySideNav();
});
