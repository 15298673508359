const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_RELEVANCE = 'relevance';
const SORT_ENTRY_NUMBER = 'entryNumber';

const PEZ_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_ENTRY_NUMBER,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_ENTRY_NUMBER]: {
        label: 'Entry No',
        value: ['pez_work_number.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    if ($vm.sort === SORT_ENTRY_NUMBER) {
      return ['pez_work_number.keyword'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_ENTRY_NUMBER;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.pez_entry_urn[0]),
  queries: {
    work_numbers: '',
    sender_names: '',
    sender_locations: '',
    receiver_names: '',
    not_before_ymd: '',
    not_after_ymd: '',
  },
  lookups: {
    work_numbers: [],
    sender_names: [],
    sender_locations: [],
    receiver_names: [],
    period_sent_starts: [],
    period_sent_ends: [],
  },
  filters: {
    work_numbers: null,
    sender_names: null,
    sender_locations: null,
    receiver_names: null,
    not_before_ymd: '',
    not_after_ymd: '',
  },
  facets: [
    {
      label: 'Letter number',
      value: 'work_numbers',
      toolTipText: 'Filter by letter number',
      placeholder: '',
    },
    {
      label: 'Sender name',
      value: 'sender_names',
      toolTipText: 'Filter by sender name',
      placeholder: '',
    },
    {
      label: 'Sender location',
      value: 'sender_locations',
      toolTipText: 'Filter by sender location',
      placeholder: '',
    },
    {
      label: 'Receiver name',
      value: 'receiver_names',
      toolTipText: 'Filter by receiver name',
      placeholder: '',
    },
    {
      kind: 'dateRangeYMD',
      min: {
        badgeLabel: 'Date >=',
        filterLabel: 'From',
        value: 'not_before_ymd',
        toolTipText: 'Enter a date in the format yyyy-mm-dd',
        placeholder: 'yyyy-mm-dd',
      },
      max: {
        badgeLabel: 'Date <=',
        filterLabel: 'Until',
        value: 'not_after_ymd',
        toolTipText: 'Enter a date in the format yyyy-mm-dd',
        placeholder: 'yyyy-mm-dd',
      },
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default PEZ_SCHEMA;
