<template>
  <div :class="['text-group', { open : open || filtered }]">
    <h4>
      <div class="toggle">
        <span
          v-if="showWorksInline && !filtered"
          class="open-toggle"
          @click.prevent="toggle"
        >
          <icon
            v-if="open"
            name="chevron-down"
          />
          <icon
            v-else
            name="chevron-right"
          />
        </span>
      </div>
      <div class="label">
        <a :href="getLibraryURL(textGroup)">
          {{ textGroup.label }}
        </a>
      </div>
      <div class="urn">
        <span>{{ safeURN(textGroup.urn) }}</span>
      </div>
    </h4>
    <text-loader v-if="loading" />
    <div
      v-if="showWorksInline && (open || filtered)"
      class="works"
    >
      <div
        v-for="work in works"
        :key="work.urn"
        class="work"
      >
        <div class="filler">
          &nbsp;
        </div>
        <div class="label">
          <a :href="getLibraryURL(work)">
            {{ work.label }}
          </a>
        </div>
        <div class="urn">
          {{ safeURN(work.urn) }}
        </div>
        <div class="versions">
          <TextVersion
            v-for="text in getTexts(work)"
            :key="text.urn"
            :text="text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextVersion from './TextVersion.vue';
import Skeleton2NavigationMixin from '../mixins/Skeleton2NavigationMixin.vue';
import {RenderOptions} from '../rendering';
import TextLoader from '../components/TextLoader.vue';
import {LOAD_TEXTGROUP_WORKS} from '../queries';

export default {
  components: { TextVersion, TextLoader },
  mixins: [Skeleton2NavigationMixin],
  props: ['textGroup', 'filtered'],
  data() {
    return {
      loading: false,
      works: [],
      texts: [],
      open: false,
      showWorksInline: RenderOptions
        .fromTextGroup(this.textGroup)
        .get('showWorksInline', true),
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
      if (this.works.length === 0) {
        this.loadWorks();
      }
    },
    loadWorks() {
      this.loading = true;
      const textGroupUrnStartsWith = `${this.safeURN(this.textGroup.urn)}.`
      this.$gql(LOAD_TEXTGROUP_WORKS, { urn:  textGroupUrnStartsWith })
        .then(({ works, versions }) => {
          this.works = works.edges.map(edge => edge.node);
          this.texts = versions.edges.map(edge => edge.node);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTexts(work) {
      const workUrn = this.safeURN(work.urn);
      const filterableWorkUrn = `${workUrn}.`;
      // eslint-disable-next-line max-len
      return this.texts ? this.texts.filter(version => version.urn.startsWith(filterableWorkUrn)) : this.texts;
    },
  },
};
</script>
