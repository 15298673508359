<template>
  <div v-if="!loaded">Loading</div>
  <router-view v-else></router-view>
</template>

<style src="../css/fonts.css"></style>

<script>
  import WebFontLoader from 'webfontloader';
  export default {
    created() {
      WebFontLoader.load({
        custom: {
          families: [
            'AbyssinicaSIL',
            'Aegean',
            'Antinoou',
            // 'BabelStoneHan',
            'Brill',
            'Epichoric',
            'Bukyvede',
            'EastSyriacAdiabene',
            'EstrangeloTalada',
            // 'HanaMinA',
            // 'HanaMinB',
            'ImperialAramaic',
            'NotoSerifArmenian',
            'NotoSerifGeorgian',
            'NotoSansGothic',
            'NotoSansLisu',
            'Orkun',
            'Paleo-Hebrew',
            'Samaritan',
            'SBLHebrew',
            'Scheherazade',
            'Serto',
            'Tibetan_Machine_Uni',
            'Uttara',
          ]
        },
        // active: this.setFontLoaded,
      });
    },
    watch: {},
    computed: {},
    data() {
      return {
        loaded: true
      }
    }
  }
</script>

<style type="text/css">
  @font-face {
    font-family: AbyssinicaSIL;
    src: url("https://data.brill.com/fonts/AbyssinicaSIL-R.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Aegean;
    src: url("https://data.brill.com/fonts/Aegean.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Antinoou;
    src: url("https://data.brill.com/fonts/Antinoou.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: BabelStoneHan;
    src: url("https://data.brill.com/fonts/BabelStoneHan.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Brill;
    src: url("https://data.brill.com/fonts/BrillRoman.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Brill;
    src: url("https://data.brill.com/fonts/BrillItalic.woff") format("woff");
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Brill;
    src: url("https://data.brill.com/fonts/BrillBold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Brill;
    src: url("https://data.brill.com/fonts/BrillBoldItalic.woff") format("woff");
    font-style: italic;
    font-weight: bold;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Epichoric;
    src: url("https://data.brill.com/fonts/BrillEpichoric.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Epichoric;
    src: url("https://data.brill.com/fonts/BrillEpichoric.woff2") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Bukyvede;
    src:url("https://data.brill.com/fonts/Bukyvede.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Bukyvede;
    src: url("https://data.brill.com/fonts/Bukyvede-Bold.woff") format("woff");
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Bukyvede;
    src: url("https://data.brill.com/fonts/Bukyvede-Italic.woff") format("woff");
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: EastSyriacAdiabene;
    src: url("https://data.brill.com/fonts/EastSyriacAdiabene.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: EstrangeloTalada;
    src: url("https://data.brill.com/fonts/SyrCOMTalada.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: HanaMinA;
    src: url("https://data.brill.com/fonts/HanaMinA.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: HanaMinB;
    src: url("https://data.brill.com/fonts/HanaMinB.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: ImperialAramaic;
    src: url("https://data.brill.com/fonts/Aramaic-Imperial-Yeb.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: NotoSansGothic;
    src: url("https://data.brill.com/fonts/NotoSansGothic.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: NotoSansLisu;
    src: url("https://data.brill.com/fonts/NotoSansLisu.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: NotoSerifArmenian;
    src: url("https://data.brill.com/fonts/NotoSerifArmenian.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: NotoSerifArmenian;
    src: url("https://data.brill.com/fonts/NotoSerifArmenian-Bold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: NotoSerifGeorgian;
    src: url("https://data.brill.com/fonts/NotoSerifGeorgian.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: NotoSerifGeorgian;
    src: url("https://data.brill.com/fonts/NotoSerifGeorgian-Bold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Orkun;
    src: url("https://data.brill.com/fonts/Orkun.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Paleo-Hebrew;
    src: url("https://data.brill.com/fonts/Hebrew-Paleo-Qumran.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Samaritan;
    src: url("https://data.brill.com/fonts/Hebrew-Samaritan.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: SBLHebrew;
    src: url("https://data.brill.com/fonts/SBL_Hbrw.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Scheherazade;
    src: url("https://data.brill.com/fonts/Scheherazade-R.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Scheherazade;
    src: url("https://data.brill.com/fonts/Scheherazade-Bold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Serto;
    src:url("https://data.brill.com/fonts/SyrCOMBatnan.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Serto;
    src: url("https://data.brill.com/fonts/SyrCOMBatnanBold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Tibetan_Machine_Uni;
    src:url("https://data.brill.com/fonts/Tibetan_Machine_Uni.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: Uttara;
    src:url("https://data.brill.com/fonts/Uttara.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
</style>

<style src="@scaife-viewer/scaife-widgets/dist/scaife-widgets.css"></style>
