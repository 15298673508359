/* eslint-disable vue/return-in-computed-property */
<template>
  <div
    class="entry-search-result list-group-item"
  >
    <h5 class="title">
      {{ workEntryLabel }}
    </h5>
    <!-- NOTE: We don't yet have a way to browse to fragment-level results for
    a given entry; likely YAGNI
     -->
    <span>
      <em>{{ total }} {{ resultsLabel }}</em>
    </span>
    <div class="entry-text-container btn-group" role="group">
      <a
        v-for="text in entryTexts"
        :key="text.urn"
        class="entry-text btn btn-light border"
        :href="readerPassageURL(text.urn)"
      >
        {{ text.label }}
      </a>
    </div>
  </div>
</template>
<script>
import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';

export default {
  mixins: [Skeleton2NavigationMixin],
  props: ['result', 'total'],
  computed: {
    workEntryLabel() {
      if (this.result.bnjo_entry_title) {
        const entryTitle = this.safeValueShim(this.result.bnjo_entry_title);
        const entryName = this.safeValueShim(this.result.bnjo_entry_name);
        // TODO: Come up with a better "generic" label for these, or
        // make component more specific to BNJO
        return `${entryTitle}, ${entryName}`;
      }
      if (this.result.cso_work_label) {
        return this.safeValueShim(this.result.cso_work_label);
      }
      if (this.result.dss_work_label) {
        return this.safeValueShim(this.result.dss_work_label);
      }
      if (this.result.pez_work_label) {
        return this.safeValueShim(this.result.pez_work_label);
      }
      if (this.result.vipo_work_label) {
        return this.safeValueShim(this.result.vipo_work_label);
      }
      return this.result.urn;
    },
    entryUrn() {
      return this.safeValueShim(this.result.bnjo_entry_urn);
    },
    entryTexts() {
      if (this.result.bnjo_entry_title) {
        let entry_texts = this.safeValueShim(this.result.bnjo_entry_texts)

        entry_texts.sort(function(a, b) {
          const sortIdxA = parseInt(a.sort_idx);
          const sortIdxB = parseInt(b.sort_idx);
          const labelA = a.label.toUpperCase;
          const labelB = b.label.toUpperCase;

          if (sortIdxA < sortIdxB) {
            return -1;
          } else if (sortIdxA > sortIdxB) {
            return 1
          } else if (labelA < labelB) {
            return -1;
          } else if (labelA > labelB) {
            return 1
          } else {
            return 0;
          }
        });

        return entry_texts;
      }
      if (this.result.cso_work_label) {
        return this.safeValueShim(this.result.cso_entry_texts);
      }
      if (this.result.dss_work_label) {
        return this.safeValueShim(this.result.dss_entry_texts);
      }
      if (this.result.pez_work_label) {
        let entry_texts = this.safeValueShim(this.result.pez_entry_texts)

        entry_texts.sort(function(a, b) {
          const sortIdxA = parseInt(a.sort_idx);
          const sortIdxB = parseInt(b.sort_idx);
          const labelA = a.label.toUpperCase;
          const labelB = b.label.toUpperCase;

          if (sortIdxA < sortIdxB) {
            return -1;
          } else if (sortIdxA > sortIdxB) {
            return 1
          } else if (labelA < labelB) {
            return -1;
          } else if (labelA > labelB) {
            return 1
          } else {
            return 0;
          }
        });

        return entry_texts;
      }
      if (this.result.vipo_work_label) {
        let entry_texts = this.safeValueShim(this.result.vipo_entry_texts)

        entry_texts.sort(function(a, b) {
          const sortIdxA = parseInt(a.sort_idx);
          const sortIdxB = parseInt(b.sort_idx);
          const labelA = a.label.toUpperCase;
          const labelB = b.label.toUpperCase;

          if (sortIdxA < sortIdxB) {
            return -1;
          } else if (sortIdxA > sortIdxB) {
            return 1
          } else if (labelA < labelB) {
            return -1;
          } else if (labelA > labelB) {
            return 1
          } else {
            return 0;
          }
        });

        return entry_texts;
      }
      return [];
    },
    readerFirstPassageUrl() {
      return this.getReaderURL({ urn: this.entryUrn });
    },
    resultsLabel() {
      return this.total === 1 ? 'passage' : 'passages';
    },
  },
  methods: {
    readerPassageURL(urn) {
      return this.getReaderURL({ urn });
    },
    // TODO: Provide a better hint on either the indexing
    // or retrieval side to avoid this shim
    safeValueShim(value) {
      return Array.isArray(value) ? value[0] : value;
    },
  },
};
</script>

<style lang="scss" scoped>
.entry-text-container {
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
  margin-top: 1rem;
}
</style>
