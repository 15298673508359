<template>
  <div
    v-if="links_new_tab"
    class="list-group-item list-group-item-action"
  >
    <div class="row">
      <div class="col-11">
        <h5 class="title">
          <a :href="readerFirstPassageUrl">
            {{ label }}
          </a>
        </h5>
        <template v-if="showTotals">
          <span v-if="total != 1">
            {{ total }} results
          </span>
          <span v-else>
            {{ total }} result
          </span>
        </template>
        <div class="urn">
          {{ versionUrn }}
        </div>
      </div>
      <div class="col-1">
        <a :onclick="createButtonAction">
          <icon name="window-restore" />
        </a>
      </div>
    </div>
  </div>
  <a
    :href="readerFirstPassageUrl"
    class="list-group-item list-group-item-action"
    v-else
  >
    <h5 class="title">
      {{ label }}
    </h5>
    <!-- TODO: Implement highlighting / passages? -->
    <template v-if="showTotals">
      <span v-if="total != 1">
        {{ total }} results
      </span>
      <span v-else>
        {{ total }} result
      </span>
    </template>
    <div class="urn">
      {{ versionUrn }}
    </div>
  </a>
</template>
<script>
import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';

export default {
  mixins: [Skeleton2NavigationMixin],
  props: ['result', 'total', 'links_new_tab'],
  data() {
    return {
      // TODO: Pass as property / not SEG
      showTotals: false,
    };
  },
  computed: {
    label() {
      // TODO: Pass this as a property, e.g.
      // not assume SEG?
      return this.result.seg_entry_title[0];
    },
    versionUrn() {
      return this.result['text.urn'][0];
    },
    readerFirstPassageUrl() {
      return this.getReaderURL({ urn: this.versionUrn });
    },
    createButtonAction() {
      const url = this.readerFirstPassageUrl;
      return `window.open('${url}','_blank')`;
    },
  },
};
</script>
