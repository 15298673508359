import { generateCollectionSchema } from './cts_collection';

// NOTE: `generateCollectionSchema` is used rather than
// spread syntax because we want to extend, not modify
// in place
const MULTI_TG_COLLECTION_SCHEMA = generateCollectionSchema();


MULTI_TG_COLLECTION_SCHEMA.queries.text_group_labels = '';
MULTI_TG_COLLECTION_SCHEMA.lookups.text_group_labels = [];
MULTI_TG_COLLECTION_SCHEMA.filters.text_group_labels = null;
MULTI_TG_COLLECTION_SCHEMA.facets.unshift(
  {
    label: 'Text Group',
    value: 'text_group_labels',
    toolTipText: 'Filter by text group',
    placeholder: '',
  },
);
export default MULTI_TG_COLLECTION_SCHEMA;
