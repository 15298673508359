<template>
  <div
    v-if="shown"
    class="btn btn-sm btn-primary mr-2"
    @click="$emit('reset', filterName)"
  >
    {{ label }}: <span v-html="value" /> <span
      class="badge"
    >
      <span class="fa fa-times" />
    </span>
  </div>
</template>

<script>
export default {
  props: ['label', 'value', 'shown', 'filterName'],
};
</script>
