import { render, staticRenderFns } from "./BaseWidget.vue?vue&type=template&id=4fcf69d0&"
import script from "./BaseWidget.vue?vue&type=script&lang=js&"
export * from "./BaseWidget.vue?vue&type=script&lang=js&"
import style0 from "./BaseWidget.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "BaseWidget.vue"
export default component.exports