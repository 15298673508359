<template>
  <div>
    <div
      v-if="loading"
      class="plot-loader"
    >
      <page-loader />
    </div>
    <div
      v-if="!loading"
      class="gridjs__container"
    >
      <grid
        :cols="columns"
        :rows="rows"
        :theme="'none'"
      />
      <b-button
        :id="`datamenu-${id}`"
        variant="default"
        class="datamenu-control"
        size="sm"
      >
        Data <img
          :src="brillImage"
          width="20"
        >
      </b-button>

      <div>&nbsp;</div>

      <b-popover
        :target="`datamenu-${id}`"
        triggers="click"
      >
        <div>
          <dl>
            <dt>Database</dt>
            <dd>
              <a
                :href="meta.database.url"
                target="_blank"
              >
                {{ meta.database.title }}
              </a>
              (<a
                :href="meta.database.doi"
                target="_blank"
              >
              {{ meta.database.doi }}
              </a>)
            </dd>
            <dt>Query</dt>
            <dd>
              <a
                :href="meta.query.url"
                target="_blank"
              >
                {{ meta.query.title }}
              </a>
            </dd>
            <dt>Extras</dt>
            <dd>
              <ul class="datamenu-explore">
                <li>
                  <a
                    :href="meta.database.extras.binder_url"
                    target="_blank"
                  >
                    Jupyter Notebook
                  </a>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import PageLoader from './PageLoader.vue';

export default {
  name: 'EmbeddableGrid',
  components: { PageLoader },
  props: {
    url: { type: String, default: null },
    brillImage: { type: String, default: null },
  },
  data() {
    return {
      id: null,
      loading: true,
      columns: [],
      rows: [],
      response: {},
      meta: {query: {}, database: {}},
    };
  },
  watch: {
    url: {
      async handler() {
        const hashBuffer = await crypto.subtle.digest('SHA-1', new TextEncoder().encode(this.url));
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        this.id = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      },
      immediate: true,
    },
  },
  created() {
    fetch(this.url)
      .then(r => r.json())
      .then((response) => {
        this.columns = response.columns;
        this.rows = response.rows;
        this.response = response;
        this.meta = response.meta;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};

</script>

<style lang="scss">
  td.gridjs-td, th.gridjs-th {
    white-space: nowrap;
  }
  .gridjs-container {
    overflow: scroll;
    overflow-y: hidden;
  }
  .gridjs-th-content {
    color: #0f356c;
  }

  .datamenu-control {
    float: right;
    border: 1px solid #ccc;
    margin-left: 5px;
    margin-top: 5px;
  }
</style>
