<template>
  <div class="widget">
    <div class="sticky-block">
      <h2>
        <span @click.prevent="toggle">
          <span class="open-toggle">
            <icon name="chevron-down" v-if="open"></icon>
            <icon name="chevron-right" v-else></icon>
          </span>
          <slot name="header"></slot>
          <div class="widget-help">
            <slot name="help"></slot>
          </div>
        </span>
        <span v-if="open" class="fixed-toggle" @click.prevent="toggleFix">
          <icon name="expand" v-if="fixed"></icon>
          <icon name="compress" v-else></icon>
        </span>
      </h2>
      <div v-if="open" class="sticky-body">
        <slot name="sticky"></slot>
      </div>
    </div>
    <div v-if="open" :class="['body', { fixed }]" ref="widget-body">
      <slot name="body"></slot>
    </div>
    <div v-if="open && more && isOverFlowed" class="widget-more" @click.prevent="toggleFix">
      <span v-if="open && fixed">More...</span>
      <span v-if="open && ! fixed">Less...</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'base-widget',
    props: {
      showMore: { type: Boolean, default: false },
    },
    data() {
      return {
        open: true,
        fixed: true,
        more: this.$props.showMore,
        isOverFlowed: false
      };
    },
    methods: {
      detectOverFlow() {
          if (this.open && this.more) {
            const body = this.$refs['widget-body']
            const bodyHeight = parseInt(window.getComputedStyle(body).maxHeight)
            const contentHeight = parseInt(window.getComputedStyle(this.$slots["body"][0].elm).height)

            if( ! isNaN(contentHeight) && ! isNaN(bodyHeight)) {
              this.isOverFlowed = contentHeight > bodyHeight
            }
          }
      },
      toggle() {
        this.open = !this.open;
      },
      toggleFix() {
        this.fixed = !this.fixed;
      },
    },
    created() {
      window.addEventListener("resize", this.detectOverFlow);
    },
    destroyed() {
      window.removeEventListener("resize", this.detectOverFlow);
    },
    updated() {
        // Calling it in mounted() does not give the correct results for the rendered height of the slot.
        this.detectOverFlow()
    }
  };
</script>

<style>
.widget-help {
  margin-left: auto;
  margin-right: 10px;
}
.widget-more {
  cursor: pointer;
  text-align: right;
  font-size: 0.8rem;
  color: #868e96;
  padding-right: 0.5rem;
}
</style>
