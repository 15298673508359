<template>
  <div>
  <div class="form-group">
    <div class="row">
      <div class="col-sm-12">
        <div
          v-tooltip="field.min.toolTipText"
          class="float-right"
        >
          <i class="fas fa-info icon-small" />
        </div>
        <label>{{ field.min.filterLabel }}</label>
        <input
          :placeholder="field.min.placeholder"
          type="text"
          :value="minValue"
          class="form-control font-size-fix"
          @input="updateField(field.min.value, $event.target.value)"
        >
      </div>
    </div>
  </div>
   <div class="form-group">
    <div class="row">
      <div class="col-sm-12">
        <div
          v-tooltip="field.max.toolTipText"
          class="float-right"
        >
          <i class="fas fa-info icon-small" />
        </div>
        <label>{{ field.max.filterLabel }}</label>
        <input
          :placeholder="field.max.placeholder"
          type="text"
          :value="maxValue"
          class="form-control font-size-fix"
          @input="updateField(field.max.value, $event.target.value)"
        >
      </div>
    </div>
  </div>
  </div>
</template>
<script>

const debounce = require('lodash.debounce');

export default {
  props: ['minValue', 'maxValue', 'field'],
  methods: {
    emitChange(name, value) {
      this.$emit('update-field', name, value)
    },
    updateField: debounce(
      function f(name, value) {
        if( value === '') {
          this.emitChange(name, '')
          return;
        }
        // We require year, date and month to be set
        const v = value.split("-")
        if( v.length === 3 && v[2] !== '') {
          v[1] = String(v[1]).padStart(2, '0'); // Force mm
          v[2] = String(v[2]).padStart(2, '0'); // Force dd
          this.emitChange(name, v.join("-"))
        }
      },
      500,
    ),
  }
};
</script>

<style scoped>
.font-size-fix {
  font-size: 1.2rem !important;
}
</style>
