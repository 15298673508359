<template>
  <div class="work" :key="work.urn">
    <div class="label">
      <a :href="getLibraryURL(work)">{{ work.label }}</a>
    </div>
    <div class="text-group">
      {{ textGroup.label }}
    </div>
    <div class="urn">
      {{ safeURN(work.urn) }}
    </div>
    <div class="versions">
      <TextVersion v-for="text in getTexts(work)" :key="text.urn" :text="text" />
    </div>
  </div>
</template>

<script>
import TextVersion from './TextVersion.vue';
import Skeleton2NavigationMixin from '../mixins/Skeleton2NavigationMixin.vue';

export default {
  props: ['work', 'filtered', 'text-groups', 'versions'],
  components: { TextVersion },
  mixins: [Skeleton2NavigationMixin],
  computed: {
    textGroup() {
      return this.textGroups.filter(textGroup => this.work.urn.includes(this.safeURN(textGroup.urn)))[0];
    },
  },
  methods: {
    getTexts(work) {
      const workUrn = this.safeURN(work.urn);
      return this.versions ? this.versions.filter(version => version.urn.startsWith(workUrn)) : this.versions;
    },
  },
};
</script>
