<script>
import { safeURN } from '../urn';


export default {
  methods: {
    safeURN(urn) {
      // @@@ maintain backwards compatability
      return safeURN(urn);
    },
    getReaderURL(ctsObj) {
      // @@@ prefer firstPassageUrl and router-link in the future
      return `${this.$router.options.base}library/${this.safeURN(ctsObj.urn)}/redirect/`;
    },
    getLibraryURL(ctsObj) {
      // we currently need to ensure that library links are resolved by the Django
      // backend to pick up things such as the background image information
      return `${this.$router.options.base}library/${this.safeURN(ctsObj.urn)}/`;
    },
  },
};
</script>
