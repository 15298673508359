/* global $ */
import Vue from 'vue';

Vue.directive('tooltip', (el, binding) => {
  $(el).tooltip({
    title: binding.value,
    placement: binding.arg || 'top',
    trigger: 'hover focus',
  });
});

Vue.directive('popover', (el, binding) => {
  $(el).popover({
    placement: binding.arg,
    template: `
    <div class="popover" role="tooltip" lang="en-Latn">
      <div class="arrow"></div>
      <h3 class="popover-header"></h3>
      <div class="popover-body"></div>
    </div>
    `,
    ...binding.value,
  });
});

Vue.directive('note', (el, binding) => {
  $(el).popover({
    placement: 'bottom',
    html: true,
    sanitize: true,
    template: `
    <div class="popover note-window" role="tooltip">
      <div class="arrow"></div>
      <span class="close" onclick="$(this).parent().popover('hide')">&times;</span>
      <div class="popover-body text" lang="${binding.value.lang}"></div>
    </div>`,
    content: binding.value.content,
  });
});
