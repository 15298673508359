<template>
  <base-widget class="passage-links">
    <span slot="header">CTS URN</span>
    <div slot="body">
      <template v-if="rightPassage || parallelPassage">
        <p v-if="ctsApiUrl"><span class="side">L</span> <tt><a :href="`${ctsApiUrl}?request=GetPassage&amp;urn=${leftPassage.urn}`">{{ leftPassage.urn.toString() }}</a></tt></p>
        <p v-else><span class="side">L</span> <tt>{{ leftPassage.urn.toString() }}</tt></p>
      </template>
      <template v-else>
        <p v-if="ctsApiUrl"><tt><a :href="`${ctsApiUrl}?request=GetPassage&amp;urn=${passage.urn}`">{{ passage.urn.toString() }}</a></tt></p>
        <p v-else><tt>{{ passage.urn.toString() }}</tt></p>
      </template>

      <template v-if="rightPassage">
        <p v-if="ctsApiUrl"><span class="side">R</span> <tt><a :href="`${ctsApiUrl}?request=GetPassage&amp;urn=${rightPassage.urn}`">{{ rightPassage.urn.toString() }}</a></tt></p>
        <p v-else><span class="side">R</span> <tt>{{ rightPassage.urn.toString() }}</tt></p>
      </template>
      <template v-if="parallelPassage && parallelPassageUrn">
        <p v-if="ctsApiUrl"><span class="side">R</span> <tt><a :href="`${ctsApiUrl}?request=GetPassage&amp;urn=${parallelPassageUrn.value}`">{{ parallelPassageUrn.value }}</a></tt></p>
        <p v-else><span class="side">R</span> <tt>{{ parallelPassageUrn.value }}</tt></p>
      </template>
      <template v-if="parallelPassage && ! parallelPassageUrn">
        <p><span class="side">R</span> <tt>- Not available -</tt></p>
      </template>
    </div>
  </base-widget>
</template>

<script>

import URN from "@/js/urn";

export default {
  name: 'widget-passage-links',
  computed: {
    ctsApiUrl() {
      return process.env.CTS_API_ENDPOINT
    },
    passage() {
      return this.$store.getters['reader/passage'];
    },
    leftPassage() {
      return this.$store.state.reader.leftPassage;
    },
    rightPassage() {
      return this.$store.state.reader.rightPassage;
    },
    parallelPassage() {
      return this.$store.state.reader.parallelPassage;
    },
    parallelPassageUrn() {
      const availableLeftRefs = Object.values(this.$store.state.reader.availableRefs || {});
      const availableParallelRefs = Object.keys(this.leftPassage.metadata.parallel_passage_lu || {});

      const matchingRefs = availableParallelRefs.filter((rItem) => {
         return availableLeftRefs.find(function(lItem) {
           return rItem === lItem
         })
      })

      let reference = '';
      if( matchingRefs.length === 1 ) {
        reference = matchingRefs[0];
      } else if( matchingRefs.length >= 2 ) {
        reference = matchingRefs[0] + "-" + matchingRefs[matchingRefs.length - 1];
      }

      if( reference !== '' ) {
        return new URN(this.parallelPassage.urn.upTo('version') + ":" + reference);
      } else {
        return '';
      }
    }
  },
};
</script>
