import createPersistedState from 'vuex-persistedstate';
import { scaifeWidgets } from '@scaife-viewer/scaife-widgets';
import { MODULE_NS as SHIM_MODULE_NS } from '@scaife-viewer/store';

import createStoreShim from './v2/store';


import { library, reader } from './vuex';
import { syncLocalStorage } from './storage';
import { getBaseURL } from '@/js/api/utils';

const baseUrlKey = getBaseURL();
const debug = process.env.NODE_ENV !== 'production';

const scaifeStoreShim = createStoreShim();

const persistedStateStore = createPersistedState({
  key: `vuex-${baseUrlKey}`,
  paths: [
    'reader.sidebarLeftOpened',
    'reader.sidebarRightOpened',
    'reader.textMode',
  ],
  storage: syncLocalStorage,
});

export default function createStore() {
  return {
    modules: {
      library,
      reader,
      [scaifeWidgets.namespace]: scaifeWidgets.store,
      [SHIM_MODULE_NS]: scaifeStoreShim.store,
    },
    plugins: [persistedStateStore],
    strict: true,
    debug,
  };
}
