<template>
  <div class="corpora-stats text-center">
    <p>
      Search <strong>{{ stats.products }}</strong> publications with <strong>
        <HumanizedNumber
          :value="stats.words"
          :is-million="true"
        /> million
      </strong> words
    </p>
    <p>
      <strong><HumanizedNumber :value="stats.works" /></strong> works in
      <strong><HumanizedNumber :value="stats.texts" /></strong>
      editions, translations, and commentaries
    </p>
  </div>
</template>

<script>
import HumanizedNumber from './HumanizedNumber.vue';

export default {
  components: {
    HumanizedNumber,
  },
  props: ['stats'],
};
</script>

<style lang="scss" scoped>
  .corpora-stats {
    background: #e9ecef;
    padding: 16px;
  }
</style>
