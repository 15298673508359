import {
  CTSView,
  // TODO: Deprecate `Library` _or_ add a feature flag
  // eslint-disable-next-line no-unused-vars
  Library,
  Search,
} from '../library';
import LibraryCollectionList from '../library/metadataCollections/LibraryCollectionList.vue';
import Reader from '../reader';
import NotFound from '../pages/NotFound.vue';

export default [
  { path: '/library', component: LibraryCollectionList, name: 'library' },
  { path: '/library/:urn', component: CTSView, name: 'library_urn' },
  { path: '/reader/:urn', component: Reader, name: 'reader' },
  { path: '/search', component: Search, name: 'search' },
  { path: '*', component: NotFound },
];
