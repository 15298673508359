import localForage from 'localforage';

import { getBaseURL } from '@/js/api/utils';

const baseUrlKey = getBaseURL();

const storeOpt = { name: `se-${baseUrlKey}`, storeName: `se-${baseUrlKey}` };

function buildNSKey(key) {
  return `${process.env.baseUrlKey}:${key}`;
}

export const syncLocalStorage = {
  setItem(key, value) {
    return localStorage.setItem(buildNSKey(key), value);
  },
  getItem(key) {
    return localStorage.getItem(buildNSKey(key));
  },
  removeItem(key) {
    return localStorage.removeItem(buildNSKey(key));
  },
};

export const syncSessionStorage = {
  setItem(key, value) {
    return sessionStorage.setItem(buildNSKey(key), value);
  },
  getItem(key) {
    return sessionStorage.getItem(buildNSKey(key));
  },
  removeItem(key) {
    return sessionStorage.removeItem(buildNSKey(key));
  },
};

export default localForage.createInstance(storeOpt);
