import axios from 'axios';
import { getBaseURL } from './utils';

const baseURL = getBaseURL();

const HTTP = axios.create({
  baseURL,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
});

export default HTTP;
