<template>
  <div class="btn-group">
    <a
      class="btn btn-light read-text-link border-top border-left border-bottom"
      :href="readerPassageURL(workUrn)"
    >
      <icon name="book" />
      <!-- TODO: Use ATLAS to handle access icon -->
      <!-- TODO: Review other places we might want the access icon -->
      Read {{ humanLang }} {{ kind }} <access-icon
        v-if="false"
        :access="true"
      />
    </a>
    <button
      type="button"
      class="btn btn-light dropdown-toggle dropdown-toggle-split border-top border-right border-bottom"
      data-toggle="dropdown"
      data-display="static"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleMenu"
    >
      <span class="sr-only">
        Toggle Dropdown
      </span>
    </button>
    <div
      class="version-option dropdown-menu"
    >
      <a
        v-for="text in texts"
        :key="text.urn"
        class="dropdown-item"
        :href="readerPassageURL(text.urn)"
      >
        <div class="version-option-container">
          <div class="label">
            <access-icon :access="text.access" />
            {{ text.label }}
          </div>
          <div class="description">
            {{ text.description }}
          </div>
          <div class="metadata">
            {{ text.humanLang }} {{ text.kind }}
          </div>
        </div>
      </a>
      <text-loader v-if="texts.length === 0" />
    </div>
  </div>
</template>

<script>
import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';
import TextLoader from '@/js/components/TextLoader.vue';

export default {
  components: {
    TextLoader,
  },
  mixins: [Skeleton2NavigationMixin],
  props: ['workUrn', 'texts', 'humanLang', 'kind'],
  methods: {
    readerPassageURL(urn) {
      return this.getReaderURL({ urn });
    },

    toggleMenu() {
      this.$emit('load-texts');
    },
  },
};
</script>
