<template>
  <section>
    <div class="container">
      <div class="search-guide">
        <h3>Search Guide</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>+</kbd>
          </div>
          <div class="col">
            AND operation, results displayed only include both terms: shoe + boot
          </div>
        </div>
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>|</kbd>
          </div>
          <div class="col">
            OR operation, results displayed include either (or both) terms: shoe | boot
          </div>
        </div>
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>-</kbd>
          </div>
          <div class="col">
            NOT operation, excludes a term from the search results: shoe -boot
          </div>
        </div>
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>"</kbd>
          </div>
          <div class="col">
            Put a word or phrase inside quotes to search for an exact match: "in the night"
          </div>
        </div>
          </div>
          <div class="col-md-6">
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>*</kbd>
          </div>
          <div class="col">
            at the end of a word to display results for all ending variations: amat*
          </div>
        </div>
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>(</kbd> <kbd>)</kbd>
          </div>
          <div class="col">
            use parentheses to specify the order of the search actions: shoe + (boot | socks)
          </div>
        </div>
            <div class="row">
          <div class="col first-fixed-width">
            <kbd>~<var>num</var></kbd>
          </div>
          <div class="col">
            after a word or phrase signifies fuzziness of search i.e. shoe~1 : shoe, shot, she or
            “In the night”~1: in the night, in the first night, in the cold night
          </div>
        </div>
          </div>
        </div>
      </div>
      <form
        class="search-form"
        v-on:submit.prevent="handleProductSearch()"
      >
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Search..." :value="searchQuery" @input="handleSearchQueryChange">
          <span v-if="lemmasEnabled">
            <input
              type="radio"
              id="kind-form"
              name="form"
              :value="searchType"
              @input="handleTypeChange"
              :checked="searchType=='form'"
            >
            <label for="kind-form">Form</label>
            <input
              type="radio"
              id="kind-lemma"
              name="lemma"
              :value="searchType"
              @input="handleTypeChange"
              :checked="searchType=='lemma'"
            >
            <label for="kind-lemma">Lemma (Greek only)</label>
          </span>
        </div>
      </form>
      <text-loader v-if="firstLoading" size="7px" margin="1px" />
      <div v-if="showSearchResults" class="row">
        <div v-if="!results.length && !products.length" class="col-sm-12 text-center">
          <p class="no-results">
            No results found. Please try again.
          </p>
          <p v-if="product">
            <span class="btn btn-link" @click="handleSearch(0)">
              Search for "{{ $route.query.q }}" across all products
            </span>
          </p>
        </div>
        <div v-if="products.length" class="col-md-3">
          <search-products
            v-if="products.length"
            :products="products"
            :handle-search="handleSearch"
            :show-clear-product="showClearProduct"
            :show-products="showProducts"
            :handle-show-products-change=handleShowTextGroupsChange
            :clear-works="clearWorks"
          />
          <search-text-groups
            v-if="textGroups.length"
            :textGroups=textGroups
            :handleSearch=handleSearch
            :showClearTextGroup=showClearTextGroup
            :showTextGroups=showTextGroups
            :handleShowTextGroupsChange=handleShowTextGroupsChange
            :clearWorks=clearWorks
            :product="product"
          />
          <search-works
            v-if="works.length"
            :works=works
            :handleSearch=handleSearch
            :showClearWork=showClearWork
            :showWorks=showWorks
            :handleShowWorksChange=handleShowWorksChange
            :textGroup=textGroup
            :product="product"
          />
        </div>
        <div v-if="results.length || products.length" class="col-md-9">
          <search-pagination
            :startIndex=startIndex
            :endIndex=endIndex
            :totalResults=totalResults
            :pageNum=pageNum
            :totalPages=totalPages
            :hasNext=hasNext
            :hasPrev=hasPrev
            :handleSearch=handleSearch
          />
          <text-loader v-if="secondLoading" size="7px" margin="1px" />
          <div>
            <search-results
              :secondLoading=secondLoading
              :results=results
              :createPassageLink=createPassageLink
            />
          </div>
          <search-pagination
            :startIndex=startIndex
            :endIndex=endIndex
            :totalResults=totalResults
            :pageNum=pageNum
            :totalPages=totalPages
            :hasNext=hasNext
            :hasPrev=hasPrev
            :handleSearch=handleSearch
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '../../api';
import SearchPagination from './SearchPagination.vue';
import SearchProducts from './SearchProducts.vue';
import SearchTextGroups from './SearchTextGroups.vue';
import SearchWorks from './SearchWorks.vue';
import SearchResults from './SearchResults.vue';
import TextLoader from '../../components/TextLoader.vue';
import { GlobalSearch } from '../../tracking';

export default {
  name: 'search-view',
  data() {
    return {
      searchQuery: '',
      pageNum: null,
      startIndex: null,
      endIndex: null,
      totalPages: null,
      totalResults: null,
      results: [],
      products: [],
      textGroups: [],
      works: [],
      firstLoading: false,
      secondLoading: false,
      showSearchResults: false,
      hasNext: false,
      hasPrev: false,
      searchType: 'form',
      showClearProduct: false,
      showClearTextGroup: false,
      showClearWork: false,
      textGroup: null,
      showProducts: false,
      showTextGroups: false,
      showWorks: false,
    };
  },
  mounted() {
    const queryParams = this.$route.query;
    if (Object.entries(queryParams).length !== 0 && queryParams.constructor === Object) {
      this.searchQuery = queryParams.q;
      this.searchType = queryParams.kind || 'form';
      this.pageNum = queryParams.p;
      this.product = queryParams.product;
      this.textGroup = queryParams.tg;
      this.work = queryParams.work;
      if (this.searchQuery || this.product) {
        this.handleSearch(this.pageNum, this.product, this.textGroup, this.works);
      }
    }
  },
  computed: {
    lemmasEnabled() {
      return this.$scaife.config.search.lemmasEnabled;
    },
  },
  methods: {
    filterProductsAgainstProduct(products) {
      // NOTE: There is some subtelty on the backend that is causing it to return
      // an extra "bucket" for DSBO and DSNO (likely because their textgroup)
      // is shared across products.
      // This method ensures we only return the relevant product.
      if (this.product) {
        return products.filter(product => product.obj.urn === this.product);
      }
      return products;
    },
    handleSearchQueryChange(e) {
      this.searchQuery = e.target.value;
    },
    handleTypeChange(e) {
      this.searchType = e.target.name;
    },
    handleShowTextGroupsChange() {
      this.showTextGroups = !this.showTextGroups;
      this.showWorks = !this.showWorks;
    },
    handleShowWorksChange() {
      this.showWorks = !this.showWorks;
    },
    clearWorks() {
      this.works = [];
    },
    handleProductSearch() {
      return this.handleSearch(0, this.$route.query.product);
    },
    handleSearch(pageNum, product=null, textGroup=null, work=null) {
      const query = this.searchQuery;
      if (query !== '') {
        if (pageNum) {
          if (!this.results.length) {
            this.firstLoading = true;
          } else {
            this.secondLoading = true;
          }
          this.showClearTextGroup = false;
          this.showClearWork = false;
        } else {
          this.firstLoading = true;
          this.product = null;
          this.showSearchResults = false;
          this.showClearProduct = false;
          this.showClearTextGroup = false;
          this.showClearWork = false;
          this.textGroup = null;
          this.work = null;
          pageNum = 1;
          this.showProducts = false;
          this.showTextGroups = false;
          this.showWorks = false;
        }
        if (product) {
          this.showClearProduct = true;
          this.results = [];
          this.product = product;
        }
        if (textGroup) {
          this.showClearTextGroup = true;
          this.results = [];
          this.textGroup = textGroup;
        }
        if (work) {
          this.showClearWork = true;
          this.results = [];
          this.work = work;
        }
        if (this.textGroup) {
          this.showClearTextGroup = true;
        }
        const params = {
          kind: this.searchType,
          q: query,
          page_num: pageNum,
          product: this.product,
          text_group: this.textGroup,
          work: this.work,
          type: 'library',
        };
        api.searchText(params, (result) => {
          this.showSearchResults = true;
          this.totalPages = result.page.num_pages;
          this.pageNum = result.page.number;
          this.startIndex = result.page.start_index;
          this.endIndex = result.page.end_index;
          this.hasNext = result.page.has_next;
          this.hasPrev = result.page.has_previous;
          this.totalResults = result.total_count;
          this.results = result.results;
          this.products = this.filterProductsAgainstProduct(result.products);
          this.textGroups = result.text_groups;
          if (this.product) {
            this.showClearProduct = true;
            this.textGroups = result.text_groups;

            // NOTE: Once a product has been selected, if there is only a single
            // text group, we can go ahead and select it and save the user
            // from an additional click / facet.
            if (this.textGroups.length === 1 && !this.textGroup) {
              const newTextGroup = result.text_groups[0].obj.urn;
              return this.handleSearch(pageNum, this.product, newTextGroup, this.work);
            }
          }
          if (this.textGroup) {
            this.showClearTextGroup = true;
            this.works = result.works;
          }
          if (this.work) {
            this.showClearWork = true;
          }

          this.secondLoading = false;
          this.firstLoading = false;

          // update url state
          const urlState = {
            q: this.searchQuery,
            kind: this.searchType,
            p: this.pageNum,
          };
          if (this.product) {
            urlState.product = this.product;
          }
          if (this.textGroup) {
            urlState.tg = this.textGroup;
          }
          if (this.work) {
            urlState.work = this.work;
          }
          this.$router.replace({
            query: urlState,
          });
        });
      }
    },
    createPassageLink(link) {
      if (!this.searchQuery) {
        return link;
      }

      const params = new URLSearchParams({
        q: this.searchQuery,
        qk: this.searchType,
      });
      return `${link}?${params.toString()}`;
    },
  },
  components: {
    SearchPagination,
    SearchProducts,
    SearchTextGroups,
    SearchWorks,
    SearchResults,
    TextLoader,
  },
};
</script>

<style lang="scss" scoped>
  .search-guide {
    .first-fixed-width {
      width: 4rem;
      max-width: 5rem;
    }
  }
</style>
