<template>
  <div class="ball-pulse partial-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
};
</script>
